import React, { Component } from "react";
import PropTypes from "prop-types";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import CallDispositionService from "../../../services/CallDispositions";

export default class DeleteCallDispositionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dial_sessions: [],
    };
  }
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    noIcon: PropTypes.bool,
    dispositionId: PropTypes.number,
    dispositionName: PropTypes.string,
    dial_sessions: PropTypes.array,
  };

  async componentDidMount() {
    const dial_sessions =
      await CallDispositionService.getDispositionWithDialSession(
        this.props.dispositionId
      );
    this.setState({ dial_sessions: dial_sessions });
  }

  render() {
    const { dial_sessions } = this.state;
    return (
      <ConfirmationModal
        actionDescription="Delete"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        {" "}
        {dial_sessions && dial_sessions.length > 0 ? (
          <>
            <p>
              The "{this.props.dispositionName}" Disposition is being used in
              the following dial sessions:
            </p>
            <ul>
              {dial_sessions.map(disposition => (
                <li key={disposition.id}>
                  ID {disposition.id} - {disposition.name}
                </li>
              ))}
            </ul>
          </>
        ) : null}
        <p>
          Are you sure you want to delete the "
          {this.props.dispositionName ? this.props.dispositionName : ""}" Call
          Disposition?
        </p>
      </ConfirmationModal>
    );
  }
}
