import React, { useState } from "react";
import { Button, Header, Modal, Dropdown } from "semantic-ui-react";
import CampaignTargetCallService from "../../services/CampaignTargetCall";
import EditCallForm from "../forms/EditCallForm";

function EditCallModal({
  call: propCall,
  callId,
  callIndex,
  callStatuses,
  onSuccess,
  iconTrigger = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [call, setCall] = useState(propCall || {});
  const [callUnavailable, setCallUnavailable] = useState(false);

  const handleOpen = async () => {
    if (!propCall && callId) {
      try {
        const _call = await CampaignTargetCallService.getCall(callId);
        setCall(_call);
      } catch (error) {
        if (error.response.status === 404) {
          setCallUnavailable(true);
        }
      }
    }
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCallUnavailable(false);
  };

  const handleSuccess = async updatedCall => {
    handleClose();
    onSuccess(updatedCall, callIndex);
  };

  return (
    <Modal
      trigger={
        iconTrigger ? (
          <Button
            type="button"
            size="mini"
            basic
            color="red"
            content="Edit Call"
            onClick={handleOpen}
          />
        ) : (
          <Dropdown.Item content="Edit" onClick={handleOpen} icon="edit" />
        )
      }
      size="large"
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Header content={callUnavailable ? "Call Unavailable" : "Edit Call"} />
      <Modal.Content>
        {callUnavailable ? (
          <p>This call is no longer available. It has likely been deleted.</p>
        ) : (
          <EditCallForm
            call={call}
            callIndex={callIndex}
            callStatuses={callStatuses}
            handleClose={handleClose}
            onSuccess={handleSuccess}
          />
        )}
      </Modal.Content>
      {callUnavailable && (
        <Modal.Actions>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Actions>
      )}
    </Modal>
  );
}

export default EditCallModal;
