import React, { useState, useEffect } from "react";
import { Header, Modal, Button, Dropdown, Form } from "semantic-ui-react";
import SourceStatusesService from "../../services/SourceStatus";
import RuvixxForm from "../RuvixxForm";

export default function SourceStatusModal({
  fetchStatus,
  sourceStatus = null,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [statusName, setStatusName] = useState("");

  const handleOpen = () => {
    if (!sourceStatus) {
      setStatusName("");
    }
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onSuccess = async () => {
    fetchStatus();
    handleClose();
  };

  const handleChange = (_, { value }) => {
    setStatusName(value);
  };

  const handleSubmit = async () => {
    let data = { name: statusName };
    if (sourceStatus) {
      await SourceStatusesService.edit(statusId, data);
    } else {
      await SourceStatusesService.create(data);
    }
  };

  useEffect(() => {
    if (sourceStatus) {
      setStatusName(sourceStatus.name);
      setStatusId(sourceStatus.id);
    }
  }, [sourceStatus]);

  return (
    <Modal
      open={modalOpen}
      onClose={onSuccess}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        sourceStatus ? (
          <Dropdown.Item onClick={handleOpen} content="Edit" icon="edit" />
        ) : (
          <Button
            size="tiny"
            className="item-adder"
            content="New Source Status"
            onClick={handleOpen}
          />
        )
      }
    >
      <Header content={`${sourceStatus ? "Edit" : "New"} Source Status`} />
      <Modal.Content>
        <RuvixxForm
          ready={statusName}
          onSubmit={handleSubmit}
          onSuccess={onSuccess}
        >
          <Form.Input
            inline
            name="name"
            label="Name"
            value={statusName}
            onChange={handleChange}
            autoFocus
          />
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
}
