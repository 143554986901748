import React from "react";
import InvestifationStatusService from "../../../services/InvestigationStatus";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

const DeleteInvestigationStatusModal = ({ investigationStatus, onSuccess }) => {
  const handleDelete = async () => {
    await InvestifationStatusService.deleteInvestigationStatus(
      investigationStatus.id
    );
    await onSuccess();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete"
      buttonColor="red"
      onConfirm={handleDelete}
      icon={"trash"}
      menuTrigger
      warning
      content={
        <p>Are you sure you want to delete this Investigation Status?</p>
      }
    />
  );
};

export default DeleteInvestigationStatusModal;
