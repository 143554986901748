import React, { useState } from "react";
import { Button, Dropdown, Header, Icon, Modal } from "semantic-ui-react";
import CaseForm from "./CaseForm";

export default function CaseFormModal({
  caseId,
  fetchData,
  iconTrigger = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    fetchData();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        iconTrigger ? (
          <Icon
            name="pencil"
            className="primary"
            link
            onClick={handleOpen}
            style={{ verticalAlign: "-2px" }}
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={"Edit"}
            icon="edit"
            onClick={handleOpen}
          />
        )
      }
    >
      <Header content={caseId ? "Edit Case" : "New Case"} />
      <Modal.Content>
        <CaseForm onSuccess={onSuccess} caseId={caseId} />
      </Modal.Content>
    </Modal>
  );
}
