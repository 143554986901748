import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import DialSessionForm from "./DialSessionForm";

export default function DialSessionModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    props.onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="large"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        props.button ? (
          <Button
            content="New Dial Session"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={
              props.dialSessionId
                ? props.isClone
                  ? "Clone"
                  : "Edit"
                : "New Dial Session"
            }
            icon={
              props.dialSessionId ? (props.isClone ? "clone" : "edit") : null
            }
            onClick={handleOpen}
          />
        )
      }
    >
      <Header
        content={
          props.dialSessionId
            ? props.isClone
              ? "Clone Dial Session"
              : "Edit Dial Session"
            : "New Dial Session"
        }
      />
      <Modal.Content>
        <DialSessionForm
          onSuccess={onSuccess}
          dialSessionId={props.dialSessionId}
          campaignId={props.campaignId}
          isClone={props.isClone}
        />
      </Modal.Content>
    </Modal>
  );
}
