import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import ConstantContactAccountsService from "../../../services/ConstantContactAccounts";

const AUTHENTICATED = "authenticated";
const PENDING = "pending";
const UNAUTHENTICATED = "unauthenticated";

export default function AccountAuthenticationModal({ account, onClose }) {
  const [authenticationStatus, setAuthenticationStatus] = useState(
    account.authentication_status
  );
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [authUrl, setAuthUrl] = useState("");
  const [stopPolling, setStopPolling] = useState(false);

  const checkStatus = async () => {
    const { authentication_status } = await ConstantContactAccountsService.get(
      account.id
    );
    setAuthenticationStatus(authentication_status);
    if (modalOpen && authentication_status === PENDING) {
      setTimeout(checkStatus, 1000);
    }
  };

  const fetchAuthUrl = async () => {
    const authUrl = await ConstantContactAccountsService.getAuthURL(account.id);
    setAuthUrl(authUrl);
  };

  const handleOpen = () => {
    setModalOpen(true);
    fetchAuthUrl();
  };

  const handleClose = () => {
    setClicked(false);
    setModalOpen(false);
    onClose && onClose();
  };

  const handleClick = () => {
    window.open(authUrl, "_blank", "noopener,noreferrer");
    setAuthenticationStatus(PENDING);
    setClicked(true);
    setTimeout(checkStatus, 1000);
  };

  const renderContent = () => {
    if (!clicked) {
      return (
        <Button primary disabled={!authUrl} onClick={handleClick}>
          Authenticate with Constant Contact
        </Button>
      );
    } else if (authenticationStatus === AUTHENTICATED) {
      return <div>Successfully Authenticated</div>;
    } else if (authenticationStatus === UNAUTHENTICATED) {
      return <div>Authentication Failed</div>;
    } else {
      return <div>Authenticating...</div>;
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        <Dropdown.Item
          onClick={handleOpen}
          icon={"key"}
          content={
            account.authentication_status === AUTHENTICATED
              ? "Re-Authenticate"
              : "Authenticate"
          }
        />
      }
    >
      <Header content={"Authenticate Account"} />
      <Modal.Content>{renderContent()}</Modal.Content>
    </Modal>
  );
}
