import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Dropdown } from "semantic-ui-react";
import CustomFieldGroupsService from "services/CustomFieldGroups";

import RuvixxForm from "components/RuvixxForm";
import CustomFieldService from "services/CustomField";
import startCase from "lodash/startCase";

const CustomFieldGroupModal = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modelOptions, setModelOptions] = useState([]);
  const [modelCustomFields, setModelCustomFields] = useState([]);
  const [formData, setFormData] = useState({});

  const handleOpen = () => {
    setFormData({
      name: props.customFieldGroup?.name || "",
      modelType: props.customFieldGroup?.model_type || "",
      description: props.customFieldGroup?.description || "",
      customFieldConfigs: props.customFieldGroup?.custom_field_configs.map(
        cfc => {
          return cfc.label;
        }
      ),
    });
    setModalOpen(true);
  };

  const handleClose = () => {
    clearForm();
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    if (!props.customFieldGroup) {
      await CustomFieldGroupsService.createCustomFieldGroup(formData);
    } else {
      const editFormData = {
        id: props.customFieldGroup.id,
        ...formData,
      };
      await CustomFieldGroupsService.editCustomFieldGroup(editFormData);
    }
  };

  const handleSuccess = () => {
    props.fetchData();
    handleClose();
  };

  const cancel = () => {
    handleClose();
  };

  const fetchCustomFieldConfigs = async modelType => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel(modelType);
    const optionCustomFieldConfigs = customFieldConfigs.map(item => {
      return { id: item.id, value: item.label, text: item.label };
    });
    setModelCustomFields(optionCustomFieldConfigs);
  };

  const clearForm = () => {
    setFormData({
      name: "",
      modelType: "",
      customFieldConfigs: [],
      description: "",
    });
  };

  const handleChange = (e, data) => {
    const additionalData = {
      [data.name]: data.value,
    };

    if (data.name === "modelType") {
      additionalData["customFieldConfigs"] = [];
    }

    setFormData(prevState => ({
      ...prevState,
      ...additionalData,
    }));
  };

  useEffect(() => {
    if (formData.modelType) {
      fetchCustomFieldConfigs(formData.modelType);
    }
  }, [formData.modelType]);

  useEffect(() => {
    const fetchModelTypes = async () => {
      let modelTypes =
        await CustomFieldService.getCustomFieldConfigModelTypes();
      const optionModelTypes = modelTypes.map(item => {
        return { id: item, value: item, text: startCase(item) };
      });
      setModelOptions(optionModelTypes);
    };
    fetchModelTypes();
  }, []);

  return (
    <Modal
      size="small"
      trigger={
        props.button ? (
          <Button
            content="New Custom Field Group"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={props.customFieldGroup ? "Edit" : "New Custom Field Group"}
            icon={props.customFieldGroup ? "edit" : null}
            onClick={handleOpen}
          />
        )
      }
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>
        {props.customFieldGroup ? "Edit" : "New"} Custom Field Group
      </Modal.Header>
      <Modal.Content>
        <RuvixxForm
          ready={
            !!formData.name &&
            !!formData.modelType &&
            !!formData.customFieldConfigs &&
            formData.customFieldConfigs.length > 0
          }
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onCancel={cancel}
        >
          <Form.Input
            inline
            name="name"
            label="Name"
            required
            onChange={handleChange}
            value={formData.name}
          />
          <Form.Select
            inline
            search
            clearable
            required
            name="modelType"
            label="Model Type"
            placeholder="Select Model Type"
            onChange={handleChange}
            options={modelOptions}
            value={formData.modelType}
          />
          <Form.Select
            inline
            search
            multiple
            clearable
            required
            name="customFieldConfigs"
            label="Custom Fields"
            placeholder="Select Custom Fields"
            onChange={handleChange}
            options={modelCustomFields}
            value={formData.customFieldConfigs}
          />
          <Form.Input
            fluid
            name="description"
            label="Description"
            onChange={handleChange}
            value={formData.description}
          />
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
};
// }

export default CustomFieldGroupModal;
