import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Select,
  Table,
} from "semantic-ui-react";
import "./PhoneNumbers.scoped.scss";
import { setEveryItemPropertyAs } from "components/helpers";

import CONSTANTS from "../constants/Constants";
import ValidatePhoneNumberModal from "./modals/ValidatePhoneNumberModal";

const contactPhoneTypes = CONSTANTS.CONTACT_PHONE_TYPES;

function makeNewNumber(type) {
  return {
    is_primary: false,
    number: "",
    ext: "",
    type: contactPhoneTypes.DEFAULT,
    enabled: type !== "user",
    is_callable: true,
  };
}

const PHONE_TYPE_OPTIONS = Object.keys(contactPhoneTypes).map(key => ({
  key: parseInt(contactPhoneTypes[key]),
  value: parseInt(contactPhoneTypes[key]),
  text: key,
}));

const PhoneNumbers = ({
  phoneNumbers,
  addPhoneNumber,
  removePhoneNumber,
  updatePhoneNumber,
  type,
}) => {
  const handleAddNumber = () => {
    addPhoneNumber(makeNewNumber(type));
  };

  const handleNumberEdit = (index, data, error) => {
    let phoneNumbersCopy = [...phoneNumbers];
    let propName = data.name.split("-")[1];
    phoneNumbersCopy[index][propName] =
      data.type === "checkbox" ? data.checked : data.value;

    if (propName === "is_primary" && data.checked) {
      setEveryItemPropertyAs(phoneNumbersCopy, propName, false, index);
    }
    updatePhoneNumber(phoneNumbersCopy[index], index, error);
  };

  const handleValidated = (status, index) => {
    let phoneNumbersCopy = [...phoneNumbers];
    phoneNumbersCopy[index]["enabled"] = status === "success";
    updatePhoneNumber(phoneNumbersCopy[index], index);
  };

  const handleRemovePhoneNumber = index => {
    removePhoneNumber(index);
  };

  const callable = phoneNumbers.some(
    ({ enabled, is_callable }) => enabled && is_callable
  );

  return (
    <div className="phone-numbers-list">
      {phoneNumbers !== undefined &&
      phoneNumbers !== null &&
      phoneNumbers.length > 0 ? (
        <>
          <p className={`${callable ? "some" : "none"} enabled-label`}>
            {callable ? "" : "Not "}Callable{" "}
            {callable ? (
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="call icon"
              >
                <path
                  d="M15 1.40621C15 8.91994 8.90881 15 1.40621 15C1.24722 15 1.09293 14.9461 0.968504 14.8471C0.844079 14.7481 0.756861 14.6099 0.721073 14.455L0.0180048 11.4081C-0.0190672 11.2474 0.00149801 11.0788 0.0761065 10.9318C0.150715 10.7848 0.274621 10.6686 0.42617 10.6037L3.70737 9.1975C3.84754 9.13743 4.00343 9.12455 4.15156 9.16081C4.29968 9.19707 4.432 9.28051 4.52856 9.39854L5.98158 11.1744C8.27582 10.098 10.1205 8.22789 11.1744 5.98158L9.39848 4.52856C9.28045 4.432 9.19701 4.29968 9.16075 4.15156C9.12449 4.00343 9.13737 3.84754 9.19744 3.70737L10.6037 0.42617C10.6686 0.274621 10.7848 0.150715 10.9318 0.0761068C11.0788 0.00149834 11.2474 -0.0190673 11.4081 0.0180046L14.455 0.721073C14.6099 0.756823 14.7481 0.84403 14.8471 0.968463C14.9461 1.0929 15 1.24721 15 1.40621Z"
                  fill="#01B604"
                />
              </svg>
            ) : (
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="call icon"
              >
                <path
                  d="M18.4738 14.6565L1.04892 1.03429C0.847863 0.874028 0.553564 0.906081 0.393302 1.10714L0.101917 1.47137C-0.0583446 1.67242 -0.0262923 1.96672 0.174763 2.12698L17.5996 15.7492C17.8007 15.9095 18.095 15.8774 18.2552 15.6764L18.5466 15.3122C18.7069 15.1111 18.6748 14.8168 18.4738 14.6565Z"
                  fill="#B4B4B4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.3815 9.19142C14.9371 6.98962 15.8513 4.30173 15.8513 1.39865C15.8513 1.2405 15.7977 1.08702 15.6993 0.963264C15.6008 0.839504 15.4633 0.752768 15.3092 0.717212L12.2788 0.0179432C12.119 -0.0189284 11.9514 0.00152608 11.8051 0.0757313C11.6589 0.149936 11.5434 0.273173 11.4788 0.423902L10.0802 3.68737C10.0204 3.82677 10.0076 3.98183 10.0437 4.12915C10.0797 4.27647 10.1627 4.40808 10.2801 4.50412L12.0464 5.94928C11.7918 6.492 11.4908 7.01264 11.1482 7.50525L13.3815 9.19142ZM10.6698 12.0421L8.57013 10.1224C8.04175 10.5018 7.47692 10.8348 6.88167 11.114L5.43651 9.34777C5.34047 9.23038 5.20886 9.1474 5.06154 9.11133C4.91422 9.07526 4.75916 9.08807 4.61975 9.14782L1.35629 10.5464C1.20556 10.611 1.08232 10.7265 1.00812 10.8728C0.933914 11.019 0.91346 11.1867 0.950331 11.3464L1.6496 14.3769C1.68519 14.5309 1.77194 14.6684 1.89569 14.7669C2.01944 14.8653 2.1729 14.9189 2.33103 14.919C5.47618 14.919 8.37194 13.8447 10.6698 12.0421Z"
                  fill="#B4B4B4"
                />
              </svg>
            )}
          </p>
          <Table size="small" basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Number</Table.HeaderCell>
                <Table.HeaderCell>Ext</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Primary?</Table.HeaderCell>
                {type === "user" ? (
                  <Table.HeaderCell>Validated</Table.HeaderCell>
                ) : (
                  <>
                    <Table.HeaderCell>Enabled?</Table.HeaderCell>
                    <Table.HeaderCell>Callable?</Table.HeaderCell>
                  </>
                )}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {phoneNumbers.map((phoneNumber, index) => {
                return (
                  <Table.Row key={index} className="editing">
                    <>
                      <Table.Cell>
                        <Input className="phone-list-input">
                          <PhoneInput
                            placeholder="Enter Phone Number"
                            name="edit-number"
                            value={phoneNumber.number}
                            onChange={number => {
                              handleNumberEdit(index, {
                                name: "edit-number",
                                value: number,
                              });
                            }}
                          />
                        </Input>
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          className="phone-list-input"
                          placeholder="Ext."
                          name="edit-ext"
                          value={phoneNumber.ext}
                          onChange={(_, data) => {
                            handleNumberEdit(index, data);
                          }}
                          style={{ width: "50px" }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                          required
                          fluid
                          options={PHONE_TYPE_OPTIONS}
                          name="edit-type"
                          value={phoneNumber.type}
                          onChange={(_, data) => {
                            handleNumberEdit(index, data);
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          size="tiny"
                          toggle
                          name="edit-is_primary"
                          checked={phoneNumber.is_primary === true}
                          onChange={(_, data) => {
                            handleNumberEdit(index, data);
                          }}
                        />
                      </Table.Cell>

                      {type === "user" ? (
                        <Table.Cell textAlign="center">
                          {phoneNumber.enabled ? (
                            <Icon name="check" />
                          ) : (
                            <ValidatePhoneNumberModal
                              phoneNumber={phoneNumber}
                              onClose={status => {
                                handleValidated(status, index);
                              }}
                            />
                          )}
                        </Table.Cell>
                      ) : (
                        <>
                          <Table.Cell>
                            <Checkbox
                              toggle
                              size="tiny"
                              name="edit-enabled"
                              checked={phoneNumber.enabled === true}
                              onChange={(_, data) => {
                                handleNumberEdit(index, data);
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              toggle
                              size="tiny"
                              name="edit-is_callable"
                              checked={phoneNumber.is_callable === true}
                              onChange={(_, data) => {
                                handleNumberEdit(index, data);
                              }}
                            />
                          </Table.Cell>
                        </>
                      )}
                      <Table.Cell className="actions">
                        <div>
                          <Icon
                            link
                            name="cancel"
                            onClick={() => handleRemovePhoneNumber(index)}
                            title="Cancel edit"
                          />
                        </div>
                      </Table.Cell>
                    </>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      ) : (
        <p>No Phone Numbers</p>
      )}
      <Button
        type="button"
        icon="plus"
        basic
        size="tiny"
        onClick={handleAddNumber}
        content="Add Phone Number"
      />
    </div>
  );
};

export default PhoneNumbers;
