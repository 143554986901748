import isFunction from "lodash/isFunction";
import isObject from "lodash/isPlainObject";
import mapValues from "lodash/mapValues";
import moment from "moment-timezone";
import * as numbro from "numbro";
import React from "react";
import { Icon, Message } from "semantic-ui-react";
import store from "store";

import CONSTANTS, { SYSTEM_ROLES } from "../constants/Constants";

function getUserTimeZone() {
  const userAuth = store.get("userAuth") || {};
  return userAuth.timeZone || moment.tz.guess(true);
}

function formatCampaignStatus(status) {
  const statuses = {
    [CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE]: "Active",
    [CONSTANTS.CAMPAIGN_STATUS_TYPES.INACTIVE]: "Inactive",
    [CONSTANTS.CAMPAIGN_STATUS_TYPES.ARCHIVED]: "Archived",
  };
  return Object.keys(statuses).includes(String(status))
    ? statuses[status]
    : "ERROR: status not found";
}

function createDateTimeFormatter({ timeZone, format } = {}) {
  if (!timeZone) timeZone = getUserTimeZone();
  if (!format) format = "YYYY-MM-DD, hh:mm A z";
  //'08/28/2019, 12:00 AM'
  //https://momentjs.com/docs/#/displaying/format/

  function formatter(isoDateString) {
    if (!moment(isoDateString, moment.ISO_8601).isValid()) {
      return isoDateString;
    }
    const fmt = isFunction(format) ? format(isoDateString) : format;
    let formattedDateString = moment
      .utc(isoDateString)
      .tz(timeZone)
      .format(fmt);
    if (fmt.endsWith(" z")) {
      formattedDateString = formattedDateString.replace(/ [+-]\d+$/, " UTC$&");
    }
    return formattedDateString;
  }

  return formatter;
}

function convertToLocalDateTime(isoDateString, timeZone) {
  if (!timeZone) timeZone = getUserTimeZone();
  return moment.utc(isoDateString).tz(timeZone);
}

const formatDate = createDateTimeFormatter();

const stringToDateTime = (dateTimeStr, timeZone = "UTC") => {
  return moment
    .utc(dateTimeStr)
    .tz(timeZone || "UTC")
    .utcOffset(0, true);
};

const tableTimestamp = value => {
  if (value !== undefined && value !== null) {
    return moment().to(moment(convertToLocalDateTime(value)));
  }
  return null;
};

const keyIsUnique = ({ key, array }) => {
  return array.length === 0
    ? true
    : !array.some(item => item.key.toLowerCase() === key.toLowerCase());
};

const updateArrayItem = (array, key, newItem = null) => {
  const index = array.findIndex(item => item.key === key);
  if (index === -1) {
    // append item if not found
    return newItem !== null ? [...array, newItem] : [...array];
  }
  const itemsBefore = array.slice(0, index);
  const itemsAfter = array.slice(index + 1);
  return newItem !== null
    ? [...itemsBefore, newItem, ...itemsAfter]
    : [...itemsBefore, ...itemsAfter];
};

const updateCustomField = (custom, setCustom, key, value = null) => {
  const customArr = updateArrayItem(custom, key, {
    key,
    value,
  });
  setCustom(customArr);
};

const checkIsCustomFieldsReady = customFieldConfigs => {
  return customFieldConfigs
    .filter(cf => cf.required)
    .every(cf => !!cf.value || cf.field_type === "boolean");
};

const getCustomFieldsForSubmit = (customFieldConfigs, customFields) => {
  return customFields.reduce((obj, cf) => {
    const cfConfig = customFieldConfigs.find(
      cfc => cfc.label.toLowerCase() === cf.key.toLowerCase()
    );
    if (
      !cfConfig ||
      (!cfConfig.required &&
        ((typeof cf.value === "string" && !cf.value.trim()) ||
          (typeof cf.value === "object" &&
            Array.isArray(cf.value) &&
            !cf.value.filter(i => i.trim())) ||
          (!cf.value && cf.value !== 0 && cf.value !== false)))
    ) {
      return obj;
    }
    switch (cfConfig.field_type) {
      case "date":
        obj[cf.key] =
          typeof cf.value === "object"
            ? cf.value.toISOString().split("T")[0]
            : cf.value;
        break;
      case "numeric":
        obj[cf.key] = Number(cf.value);
        break;
      case "select":
        obj[cf.key] =
          cfConfig.field_options.type === "single" && !Array.isArray(cf.value)
            ? [cf.value]
            : cf.value;
        if (
          !cf.value.length > 0 ||
          (cf.value.length === 1 && cf.value[0] === "")
        ) {
          delete obj[cf.key];
        }
        break;
      default:
        obj[cf.key] = cf.value;
    }
    return obj;
  }, {});
};

const deleteArrayItem = (array, key) => {
  const index = array.findIndex(item => item.key == key);
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const extractCustom = info => {
  if (info !== undefined && info !== null) {
    return Object.keys(info)
      .map(key => {
        return { key: key, value: info[key] };
      })
      .sort((a, b) => {
        const keyA = a.key.toUpperCase(); // ignore upper and lowercase
        const keyB = b.key.toUpperCase(); // ignore upper and lowercase
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
  } else {
    return [];
  }
};

const objectIsEmpty = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const objToArr = obj => {
  let arr = [];
  if (!!obj && !objectIsEmpty(obj)) {
    let keys = Object.keys(obj);
    for (const k of keys) {
      arr.push(obj[k]);
    }
  }
  return arr;
};

const compareCreatedAt = (a, b) => {
  if (a.created_at < b.created_at) {
    return 1;
  }
  if (a.created_at > b.created_at) {
    return -1;
  }
  return 0;
};

const LoadingMessage = () => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>Just one second</Message.Header>
      We are fetching that content for you.
    </Message.Content>
  </Message>
);

const CustomLoader = ({ loading, style = {} }) => {
  return loading ? (
    <div className="-loading -active">
      <Message icon className="-loading-inner" style={style}>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          We are fetching that content for you.
        </Message.Content>
      </Message>
    </div>
  ) : (
    <div />
  );
};

const capitalize = string => {
  if (typeof string !== "string") return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const sortByKey = (array, key, desc = false) => {
  return array.sort(function (a, b) {
    const [x, y] = [a[key], b[key]];
    const f = desc ? -1 : 1;
    return f * (x < y ? -1 : x > y ? 1 : 0);
  });
};

const getFormEvents = info => {
  if (
    info &&
    info.typeform_events &&
    info.typeform_events.some(r => r.event_type === "form_response")
  )
    return info.typeform_events;
  if (info && info.custom_form_events && info.custom_form_events.length > 0)
    return info.custom_form_events;
  return null;
};

const isUrl = str => {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
};

const checkIsAuthorized = (
  permissions, // [{method: "get", path: "campaigns"}]
  {
    partialMatch = false,
    shouldReturnGrantedInfo = false,
    userAuth: user = {},
    permissionCategories = {},
  } = {}
) => {
  user = store.get("userAuth") || user;
  permissionCategories =
    store.get("permissionCategories") || permissionCategories;
  const userRole = user?.role || {};
  let userPermissions = userRole?.permissions || []; // set as const

  const isAdmin = userRole.id === SYSTEM_ROLES.ADMIN;

  let isAuthorized = false;
  let grantedInfo = [];

  if (isAdmin) {
    isAuthorized = true;
    grantedInfo = permissions.map(permission => ({
      ...permission,
      granted: true,
    }));
  } else {
    permissions = permissions.map(({ method, path }) => ({
      method,
      path,
      category: (Object.entries(permissionCategories).find(
        ([category, paths]) => !!paths.find(p => path.match(RegExp(p)))
      ) || [null])[0],
    }));

    const checkResults = permissions.map(({ method, path, category }) => {
      const userPermission = userPermissions.find(
        p =>
          p.is_allowed &&
          method === p.method &&
          (category === "__default__" ||
            `@${category}` === p.path ||
            path.replaceAll("*", 0).match(RegExp(p.path)))
      );
      grantedInfo = [
        ...grantedInfo,
        { method, path, granted: !!userPermission },
      ];
      return userPermission;
    });
    isAuthorized = partialMatch
      ? checkResults.some(_ => _)
      : checkResults.every(_ => _);
  }

  if (shouldReturnGrantedInfo) {
    return { isAuthorized, grantedInfo };
  }
  return isAuthorized;
};

const mergeObjectValues = object => {
  const values = Object.values(object);
  if (values.length === 0 || Array.isArray(values[0])) {
    return values.reduce((obj, cur) => [...obj, ...cur], []);
  } else {
    return values.reduce((obj, cur) => ({ ...obj, ...cur }), {});
  }
};

const generateQueryParamsFromFilterParams = (filterParams, filters) => {
  return Object.keys(filterParams).reduce((obj, cur) => {
    const filter = filters.find(({ title }) => title === cur);
    if (!filter) {
      return obj;
    }
    const param = ((key, value, type) => {
      switch (type) {
        case "dateRange":
          return ["from", "to"].reduce(
            (filter, cur) => ({
              ...filter,
              ...(value[cur] && {
                [`${key}:${cur}`]: value[cur].format("YYYY-MM-DD"),
              }),
            }),
            {}
          );
        case "select":
        case "ruvixxSelect":
          return {
            [key]: Array.isArray(value)
              ? value.map(({ value }) => value)
              : value,
          };
        default:
          return {
            [key]: value,
          };
      }
    })(filter.key, filterParams[cur], filter.type);
    return { ...obj, ...param };
  }, {});
};

const generateQueryParamsFromCustomFilters = customFilters => {
  let cf = customFilters
    .filter(({ selectValue, inputValue }) => selectValue)
    .reduce((obj, { selectValue, inputValue }) => {
      let value = inputValue;
      if (moment.isMoment(inputValue)) {
        value = inputValue.utc().format("YYYY-MM-DD");
      }
      return {
        ...obj,
        [selectValue]: value === null ? "search_on_key" : value,
      };
    }, {});
  return cf;
};

const generateQueryParamsFromTagFilters = tagsFilters => {
  const tags = tagsFilters
    .filter(({ tags }) => tags.length > 0)
    .map(({ operationType: op, tags }) => {
      op = op === "excludes" ? "-" : "";
      tags = tags.join(",");
      return op + tags;
    })
    .join(";");
  return { tags };
};

const generateFilterParamsFromQueryParams = async (queryParams, filters) => {
  let filterParams = {};
  for (const [key, value] of Object.entries(queryParams)) {
    const filter = filters.find(filter => key.startsWith(filter.key));

    if (!filter) {
      continue;
    }
    const { title, type, data, queryFn, props } = filter;
    let param = null;
    switch (type) {
      case "dateRange":
        param = {
          ...filterParams[title],
          [key.replace(`${filter.key}:`, "")]: moment(value),
        };
        break;
      case "select":
      case "ruvixxSelect":
        param = await (async () => {
          const ids = value.split(",").map(id => {
            if (!isNaN(+id)) {
              id = +id;
            }
            if (["true", "false"].includes(id)) {
              id = id === "true";
            }
            return id;
          });
          if (!data && queryFn) {
            const data = await queryFn({ model_id: ids });
            if (props?.includeNoneOption) {
              data.unshift({
                id: "Null",
                key: "Null",
                text: "Null",
                value: "Null",
              });
            }
            return data
              .filter(({ id }) => ids.includes(id))
              .map(({ id, full_name, name, text }) => ({
                key: id,
                text: full_name || name || text || "",
                value: id,
              }));
          } else {
            return ids.map(id => {
              const item = data.find(({ id: itemId }) => itemId === id);
              return { key: id, text: item ? item.name : "", value: id };
            });
          }
        })();
        break;
      case "ruvixxToggle":
        param = String(value) === "true";
        break;
      default:
        param = value;
    }
    if (isObject(param)) {
      param = mapValues(param, value => {
        if (typeof value === "string" && !isNaN(+value)) {
          value = +value; // cast string to number
        }
        return value;
      });
    }
    filterParams = {
      ...filterParams,
      [title]: param,
    };
  }
  return filterParams;
};

const swapElements = (array, index1, index2) => {
  const res = [...array];
  [res[index1], res[index2]] = [res[index2], res[index1]];
  return res;
};

const normalizePercentage = (d0, d1) => {
  let value = d0 / d1;
  if (d1 === 0) {
    value = 0;
  }
  return value;
};

const getPercentage = (d0, d1, complement = false) => {
  let t = complement ? 1 : 0;
  return numbro(Math.abs(t - normalizePercentage(d0, d1))).format({
    output: "percent",
    mantissa: 1,
  });
};

const roundToTwoDecimals = value => {
  return typeof value === "number" &&
    !Number.isInteger(value) &&
    value.toString().split(".")[1].length > 2
    ? value.toFixed(2)
    : value;
};

const getInvoicesRowAmount = invoice => {
  if (invoice.amount === null || invoice.amount === undefined) {
    return 0;
  }

  const amount = parseFloat(invoice.amount);
  const term = invoice.term ? parseInt(invoice.term) : 1;
  const quantity = invoice.quantity ? parseInt(invoice.quantity) : 1;

  let result = amount * term * quantity;

  if (invoice.is_discount) {
    return result * -1;
  }

  return result;
};

const getInvoicesTotalValue = invoices => {
  return invoices.reduce((sum, invoice) => {
    sum += getInvoicesRowAmount(invoice);
    return sum;
  }, 0);
};

/**
 * Sets the property of all items in the [array] to a [value], except for the [exceptIndex].
 * @param {Array} array
 * @param {String} propName
 * @param {*} value
 * @param {Number} exceptIndex
 */
const setEveryItemPropertyAs = (array, propName, value, exceptIndex) => {
  for (let i = 0; i < array.length; i++) {
    if (exceptIndex !== undefined && i !== exceptIndex) {
      array[i][propName] = value;
    }
  }
};

export {
  keyIsUnique,
  updateArrayItem,
  updateCustomField,
  checkIsCustomFieldsReady,
  getCustomFieldsForSubmit,
  deleteArrayItem,
  extractCustom,
  objectIsEmpty,
  objToArr,
  getUserTimeZone,
  createDateTimeFormatter,
  formatCampaignStatus,
  formatDate,
  tableTimestamp,
  stringToDateTime,
  convertToLocalDateTime,
  compareCreatedAt,
  LoadingMessage,
  CustomLoader,
  capitalize,
  sortByKey,
  getFormEvents,
  isUrl,
  checkIsAuthorized,
  mergeObjectValues,
  generateQueryParamsFromFilterParams,
  generateFilterParamsFromQueryParams,
  generateQueryParamsFromCustomFilters,
  generateQueryParamsFromTagFilters,
  swapElements,
  getPercentage,
  roundToTwoDecimals,
  getInvoicesRowAmount,
  getInvoicesTotalValue,
  setEveryItemPropertyAs,
};
