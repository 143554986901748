import React, { Component, useState } from "react";
import {
  Button,
  Header,
  Modal,
  Confirm,
  Dropdown,
  Message,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import PropTypes from "prop-types";
import AuthService from "../../../services/Auth";
import EmailTemplateService from "../../../services/EmailTemplate";
import EmailTemplate from "../../email/EmailTemplate";

class NewEmailTemplateModal extends Component {
  static propTypes = {
    fetchTemplates: PropTypes.func.isRequired,
    mergeTags: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      confirmOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ confirmOpen: true });
  };

  handleCancelClose = () => {
    this.setState({
      confirmOpen: false,
    });
  };

  handleConfirmClose = () => {
    this.props.fetchTemplates();
    this.setState({
      modalOpen: false,
      confirmOpen: false,
    });
  };

  handleSuccess = async () => {
    await this.props.fetchTemplates();
    this.handleConfirmClose();
  };

  render() {
    return (
      <>
        <Button
          content="New Email Template"
          onClick={this.handleOpen}
          size="tiny"
          className="item-adder"
        />
        <Confirm
          className="confirm"
          open={this.state.confirmOpen}
          onConfirm={this.handleConfirmClose}
          onCancel={this.handleCancelClose}
          header="New Email Template"
          content="Are you sure? Closing this window will undo unsaved changes"
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="fullscreen"
          closeIcon
          closeOnDimmerClick={false}
        >
          <Header content="New Email Template" />
          <Modal.Content>
            <EmailTemplate isNew onSuccess={this.handleSuccess} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

class EditEmailTemplateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      confirmOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ confirmOpen: true });
  };

  handleConfirmClose = () => {
    this.setState({
      modalOpen: false,
      confirmOpen: false,
    });
  };

  handleCancelClose = () => {
    this.setState({
      confirmOpen: false,
    });
  };

  handleSuccess = async () => {
    await this.props.fetchTemplates();
    this.handleConfirmClose();
  };

  render() {
    const { id } = this.props;

    return (
      <>
        <Confirm
          className="confirm"
          open={this.state.confirmOpen}
          onConfirm={this.handleConfirmClose}
          onCancel={this.handleCancelClose}
          header="Edit Email Template"
          content="Are you sure? Closing this window will undo unsaved changes"
        />
        <Modal
          trigger={
            <Dropdown.Item
              className="primary"
              content="Edit"
              icon="edit"
              onClick={this.handleOpen}
            />
          }
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          size="fullscreen"
          closeOnDimmerClick={false}
        >
          <Header content="Edit Email Template" />
          <Modal.Content>
            <EmailTemplate id={id} onSuccess={this.handleSuccess} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

class DeleteTemplateModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    templateId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      templateCheck: [],
    };
  }

  checkTemplate = async templateId => {
    let response = await EmailTemplateService.checkIfTemplateBeingUsed(
      templateId
    );

    if (response) {
      let schedule_data = response.schedule_data;
      this.setState({ templateCheck: schedule_data });
    } else {
      this.setState({ templateCheck: response });
    }
  };

  async componentDidMount() {
    this.checkTemplate(this.props.templateId);
  }

  getErrorMessage = errorData => {
    let errorMessage = "";
    let errorItems = [];
    if (errorData.length > 0) {
      for (const e in errorData) {
        const { schedule_id, schedule_name, campaign_url } = errorData[e];
        errorMessage = (
          <li>
            The Email Template is currently scheduled to send in (Schedule Name:{" "}
            {schedule_name} ID: {schedule_id}) for this{" "}
            <Link target="_blank" to={campaign_url}>
              Campaign{" "}
            </Link>
          </li>
        );
        errorItems.push(errorMessage);
      }
      let errorList = <ul>{errorItems}</ul>;
      return errorList;
    } else {
      return null;
    }
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription="Archive"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        {!!this.state.templateCheck ? (
          <>
            <p>This template is currently being used:</p>
            {this.getErrorMessage(this.state.templateCheck)}
            <p>
              Are you sure you want to archive? (These schedules will be
              disabled on archive)
            </p>
          </>
        ) : (
          <p>Are you sure you want to archive this Email Template?</p>
        )}
      </ConfirmationModal>
    );
  }
}

const CloneTemplateModal = ({ emailTemplateId, fetchTemplates }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [template, setTemplate] = useState({
    name: "",
    subject: "",
    text: "",
    html: "",
    info: {},
    form: "",
  });

  const handleOpen = async () => {
    if (AuthService.isLoggedIn()) {
      setModalOpen(true);
    }
  };

  const handleClose = () => {
    setConfirmOpen(true);
  };

  const handleSuccess = async () => {
    await fetchTemplates();
    handleClose();
  };

  const handleConfirmClose = () => {
    fetchTemplates();
    setModalOpen(false);
    setConfirmOpen(false);
  };

  const handleCancelClose = () => {
    setConfirmOpen(false);
  };

  return (
    <>
      <Confirm
        className="confirm"
        open={confirmOpen}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
        header="Clone Email Template"
        content="Are you sure? Closing this window will undo unsaved changes"
      />
      <Modal
        trigger={
          <Dropdown.Item
            className="primary"
            content="Clone"
            icon="clone"
            onClick={handleOpen}
          />
        }
        size="fullscreen"
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header as="h3" content="Clone Template Modal" />
        <Modal.Content>
          <EmailTemplate
            id={emailTemplateId}
            isClone
            onSuccess={handleSuccess}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export {
  NewEmailTemplateModal,
  DeleteTemplateModal,
  CloneTemplateModal,
  EditEmailTemplateModal,
};
