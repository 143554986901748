import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { USER_STATUSES } from "constants/Constants";
import { useUserStatus } from "components/UserStatus/UserStatusContext";
import { useSocket } from "providers/SocketProvider";
import store from "store";

const IncomingCallContext = createContext();

export const useIncomingCall = () => useContext(IncomingCallContext);

export const IncomingCallProvider = ({ children }) => {
  const audioRef = useRef(new Audio("/notification.mp3"));
  const audioIntervalRef = useRef(null);

  const { socket } = useSocket();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isCalling, setIsCalling] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const [callInfo, setCallInfo] = useState({});
  const callInfoRef = useRef(callInfo);

  const { status } = useUserStatus();

  const openDialog = (isMinimezed = false) => {
    setIsMinimized(isMinimezed);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    stopAudio();
    setIsDialogOpen(false);
  };

  useEffect(() => {
    callInfoRef.current = callInfo;
    if (callInfo.call_id) {
      triggerNotification();
      setIsCalling(true);
    }
  }, [callInfo]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isMuted) {
      audio.volume = 0;
    } else {
      audio.volume = 0.5;
    }
  }, [isMuted]);

  useEffect(() => {
    if (socket) {
      socket.on("calling", data => {
        if (!callInfoRef.current.call_id) {
          setCallInfo(data);
          setIsMuted(data.is_muted);
        }
      });

      socket.on("stop_notification", data => {
        stopNotification();
      });

      socket.on("mute_updated", data => {
        setIsMuted(data.is_muted);
      });
    }
  }, [socket]);

  const isPowerDialer = () => {
    return window.location.pathname.startsWith("/dialer");
  };

  const isAgentOnCall = () => {
    return status === USER_STATUSES.ON_CALL.key;
  };

  const triggerNotification = async () => {
    switch (status) {
      case USER_STATUSES.ONLINE.key:
        openDialog();
        startAudio();
        updateMute(false);
        // Desktop notification
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            const notification = new Notification("You're receiving a Call!");
            notification.onclick = () => window.focus();
          }
        });
        break;
      case USER_STATUSES.ON_CALL.key:
        const openWidgetMinimized = isPowerDialer();
        openDialog(openWidgetMinimized);
        startAudio();
        updateMute(true);
        break;
    }
  };

  const stopNotification = () => {
    setIsCalling(false);
    setCallInfo({});
    stopAudio();
    closeDialog();
  };

  const startAudio = () => {
    const audio = audioRef.current;
    audio.volume = isMuted ? 0 : 0.5;
    safePlayAudio(audio);
    audioIntervalRef.current = setInterval(() => {
      audio.currentTime = 0;
      safePlayAudio(audio);
    }, 5000);
  };

  const safePlayAudio = audio => {
    audio.play().catch(err => {
      console.log(err);
    });
  };

  const stopAudio = () => {
    clearInterval(audioIntervalRef.current);
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const toggleMute = () => {
    updateMute(!isMuted);
  };

  const updateMute = newIsMuted => {
    const user = store.get("userAuth");

    if (socket) {
      socket.emit(
        "mute_updated",
        JSON.stringify({
          call_sid: callInfo.call_id,
          agent_id: user.id,
          is_muted: newIsMuted,
        })
      );
    }
  };

  return (
    <IncomingCallContext.Provider
      value={{
        isDialogOpen,
        isCalling,
        isMuted,
        callInfo,
        isMinimized,
        setCallInfo,
        toggleMute,
        stopNotification,
        setIsMinimized,
        isAgentOnCall,
      }}
    >
      {children}
    </IncomingCallContext.Provider>
  );
};
