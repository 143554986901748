import React, { useState } from "react";
import CampaignService from "../../../services/Campaign";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";

const EditCampaignStatusModal = ({ campaignStatus, fetchStatuses }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(campaignStatus.name);

  const handleSubmit = async () => {
    await CampaignService.editCampaignStatus(campaignStatus.id, name);
    fetchStatuses();
    setOpen(false);
  };

  const header = "Edit Campaign Status";

  const Trigger = (
    <Dropdown.Item content="Edit" icon="edit" onClick={() => setOpen(true)} />
  );

  return (
    <Modal trigger={Trigger} open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Name</label>
            <input
              placeholder="Campaign Status Name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Form.Field>
          <Button type="submit" primary>
            Save
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default EditCampaignStatusModal;
