import React, { createRef } from "react";
import { Container, Dropdown, Segment, Icon, Button } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "../styles/table.scss";
import "../styles/tab_view.scss";

import BaseTable from "./BaseTable";
import AuthService from "../services/Auth";
import ACL_RELATIONSHIPS from "../acl-relationships";
import withRoleCheck from "./hocs/withRoleCheck";
import CustomDropdown from "./CustomDropdown";
import setPageTitle from "../helpers/title";
import CaseDomainService from "../services/CaseDomain";
import _DeleteCaseDomainModal from "./modals/DeleteCaseDomainModal";
import CaseDomainModal from "./modals/CaseDomainModal";

const CreateCaseDomainButton = withRoleCheck(Button, [
  ACL_RELATIONSHIPS.caseDomains.create,
]);

const EditCaseDomainButton = withRoleCheck(Dropdown.Item, [
  ACL_RELATIONSHIPS.caseDomains.edit,
]);

const DeleteCaseDomainModal = withRoleCheck(_DeleteCaseDomainModal, [
  ACL_RELATIONSHIPS.caseDomains.delete,
]);

class CaseDomainsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CaseDomainService.getTable;

    this.caseDomainModal = createRef();

    this.state = {
      ...this.state,
      header: "Case Domains",
      headerIcon: "globe",
      tableName: "case_domains",
      noDataText: "No Case Domains found. Try adjusting your filters.",
      statusOptions: [],
      createButton: (
        <CreateCaseDomainButton
          size="tiny"
          content="New Case Domain"
          className="item-adder"
          onClick={() => this.caseDomainModal.current.open()}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Case Domains");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Computer Domain",
        accessor: "computer_domain",
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon name={props.value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Email Domain",
        accessor: "email_domain",
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon name={props.value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: caseDomain }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCaseDomainButton
                icon="pencil"
                content="Edit"
                onClick={() => this.caseDomainModal.current.open(caseDomain)}
              />
              <DeleteCaseDomainModal
                caseDomainId={caseDomain.id}
                fetchData={this.fetchData}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <CaseDomainModal
          ref={this.caseDomainModal}
          fetchData={this.fetchData}
        />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CaseDomainsTable;
