import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Navigate } from "react-router-dom";
import {
  Button,
  Confirm,
  Form,
  Grid,
  Input,
  Modal,
  Table,
} from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import CampaignService from "../../../services/Campaign";
import _ from "lodash";
import Datetime from "react-datetime";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import CampaignStatusPresetService from "../../../services/CampaignStatusPreset";

const isValidCampaignStatus = campaignStatus => {
  return (
    isObject(campaignStatus) &&
    isNumber(campaignStatus.id) &&
    isString(campaignStatus.name)
  );
};

const getCampaignStatusName = campaignStatus => {
  return isValidCampaignStatus(campaignStatus)
    ? campaignStatus.name
    : "(unset)";
};

const DeleteCampaignStatusModal = ({ campaignStatus, fetchStatuses }) => {
  const handleDelete = async () => {
    await CampaignService.deleteCampaignStatus(campaignStatus.id);
    await fetchStatuses();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete"
      buttonColor="red"
      onConfirm={handleDelete}
      icon={"trash"}
      warning
      content={<p>Are you sure you want to delete this Campaign Status?</p>}
    />
  );
};

const ContactConfirmModal = ({
  confirmOpen,
  entityName,
  getSelectedStatusName,
  campaignId,
  entityId,
  contactId,
  selectedStatusId,
  effectiveDate,
  updateData,
  handleClose,
  reason,
}) => {
  const changeStatus = async updateEntity => {
    await CampaignService.changeStatusForContact(
      campaignId,
      entityId,
      contactId,
      selectedStatusId,
      reason,
      effectiveDate,
      updateEntity
    );
    await updateData();
    handleClose();
  };

  return (
    <Confirm
      className="confirm"
      open={confirmOpen}
      onCancel={() => changeStatus(false)}
      onConfirm={() => changeStatus(true)}
      header="Confirm Changes"
      content={`
        Do you want to change the campaign state of the entity
        '${entityName}' to ${getSelectedStatusName()}?
      `}
      cancelButton={"No"}
      confirmButton={"Yes"}
    />
  );
};

const EntityConfirmModal = ({
  confirmOpen,
  setConfirmOpen,
  getSelectedStatusName,
  campaignId,
  entityIds,
  campaignStatusId,
  effectiveDate,
  updateData,
  handleClose,
  reason,
}) => {
  const [targetRedirect, setTargetRedirect] = useState(null);

  const changeStatus = async ({
    updateContacts = false,
    redirect = false,
  } = {}) => {
    await CampaignService.changeStatusForCampaignTargets({
      campaignId,
      entityIds,
      campaignStatusId,
      reason,
      updateContacts,
      effectiveDate,
    });
    if (redirect && entityIds.length === 1) {
      setTargetRedirect(entityIds[0]);
    } else {
      await updateData();
      handleClose();
    }
  };

  const handleChangeAllContactStates = () =>
    changeStatus({ updateContacts: true });
  const handleChangeIndividually = () => changeStatus({ redirect: true });
  const handleSkip = () => changeStatus();

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const buttons = [
    {
      content: `Change all contact states to ${getSelectedStatusName()}`,
      onClick: handleChangeAllContactStates,
    },
    {
      content: "Change Individually",
      onClick: handleChangeIndividually,
    },
    {
      content: "Skip",
      onClick: handleSkip,
    },
  ];
  return (
    <>
      <Modal
        size="small"
        open={confirmOpen}
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        onClose={handleConfirmClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header content="Confirm Changes" />
        <Modal.Content>
          <p>Do you want to change the campaign states of the contacts?</p>
          <Grid columns={1}>
            {buttons.map((props, index) => (
              <Grid.Row key={index}>
                <Grid.Column>
                  <Button
                    size="tiny"
                    basic
                    style={{ margin: "0.3em 0" }}
                    {...props}
                  />
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Modal.Content>
      </Modal>
      {targetRedirect && (
        <Navigate to={`/campaigns/${campaignId}/target/${targetRedirect}`} />
      )}
    </>
  );
};

const CampaignStatusModal = ({
  campaignStatus,
  campaignId,
  entityId,
  contactId,
  caseId,
  updateData,
  entityName,
  trigger,
  entities,
  disabled,
  effectiveDate,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [defaultStatusId, setDefaultStatusId] = useState(null);
  const [selectedStatusId, setSelectedStatusId] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [reason, setReason] = useState("");
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState(null);

  const fetchStatuses = async () => {
    const campaign = await CampaignService.getCampaign(campaignId);

    const campaignPresetId = caseId
      ? campaign.info.case_campaign_status_preset
      : campaign.info.campaign_status_preset;

    if (campaignPresetId) {
      const campaignStatusPresets =
        await CampaignStatusPresetService.getCampaignStatusPresets();
      const campaignStatusPreset = campaignStatusPresets.find(
        campaignStatusPreset => campaignStatusPreset.id === campaignPresetId
      );
      setStatuses(campaignStatusPreset.info.campaign_statuses);
    } else {
      const statuses = await CampaignService.getCampaignStatuses();
      setStatuses(statuses);
    }
  };

  const setDefaultCampaignStatus = useCallback(() => {
    if (_.isEmpty(statuses)) {
      return;
    }
    let defaultId = null;
    if (campaignStatus) {
      if (_.isString(campaignStatus)) {
        campaignStatus = statuses.find(({ name }) => name === campaignStatus);
      }
      defaultId = campaignStatus?.id;
    } else if (selectedEntities) {
      const campaign_statuses = _(selectedEntities).map("campaign_status.id");
      if (campaign_statuses.uniq().size() === 1) {
        defaultId = campaign_statuses.first();
      }
    }
    setDefaultStatusId(defaultId);
    setSelectedStatusId(defaultId);
  }, [campaignStatus, selectedEntities, statuses]);

  const handleSubmit = () => {
    if (
      defaultStatusId !== selectedStatusId ||
      effectiveDate !== selectedEffectiveDate
    ) {
      if (caseId) {
        changeStatusForCase();
      } else {
        setConfirmOpen(true);
      }
    } else {
      handleClose();
    }
  };
  const changeStatusForCase = async () => {
    await CampaignService.changeStatusForCase(
      campaignId,
      caseId,
      selectedStatusId,
      selectedEffectiveDate,
      reason
    );
    handleClose();
  };

  const allowSubmit = () => {
    if (defaultStatusId === selectedStatusId) {
      return !!selectedEffectiveDate && selectedEffectiveDate !== effectiveDate;
    } else return !!selectedEffectiveDate;
  };

  const handleOpen = () => {
    setModalOpen(true);
    setSelectedEntities(entities);
    setReason("");
    fetchStatuses();
  };

  const handleClose = () => {
    setConfirmOpen(false);
    setModalOpen(false);
    updateData();
  };

  const handleDateChange = moment => {
    let data = "";
    if (typeof moment === "string") {
      data = moment;
    } else {
      data = moment.format("YYYY-MM-DD");
    }
    setSelectedEffectiveDate(data);
  };

  const getSelectedStatusName = () => {
    const selectedStatus = statuses.find(
      status => status.id === selectedStatusId
    );
    return selectedStatus ? `'${selectedStatus.name}'` : "(unset)";
  };

  useEffect(() => {
    setDefaultCampaignStatus();
  }, [setDefaultCampaignStatus]);

  useEffect(() => {
    setSelectedEffectiveDate(moment(effectiveDate).format("YYYY-MM-DD"));
  }, [effectiveDate]);

  useEffect(() => {
    if (selectedStatusId === defaultStatusId) {
      setSelectedEffectiveDate(moment(effectiveDate).format("YYYY-MM-DD"));
    } else {
      setSelectedEffectiveDate(null);
    }
  }, [defaultStatusId, effectiveDate, selectedStatusId]);

  return (
    <>
      {!!contactId && (
        <ContactConfirmModal
          confirmOpen={confirmOpen}
          entityName={entityName}
          getSelectedStatusName={getSelectedStatusName}
          campaignId={campaignId}
          entityId={entityId}
          contactId={contactId}
          selectedStatusId={selectedStatusId}
          updateData={updateData}
          handleClose={handleClose}
          reason={reason}
          effectiveDate={selectedEffectiveDate}
        />
      )}
      {((!contactId && !!entityId) || !!selectedEntities?.length) && (
        <EntityConfirmModal
          confirmOpen={confirmOpen}
          setConfirmOpen={setConfirmOpen}
          getSelectedStatusName={getSelectedStatusName}
          campaignId={campaignId}
          entityIds={
            isEmpty(selectedEntities)
              ? [entityId]
              : selectedEntities.map(e => e.entity_id)
          }
          campaignStatusId={selectedStatusId}
          effectiveDate={selectedEffectiveDate}
          updateData={updateData}
          handleClose={handleClose}
          reason={reason}
        />
      )}
      {isValidElement(trigger) ? (
        cloneElement(trigger, { onClick: handleOpen, disabled: disabled })
      ) : (
        <>
          {isValidCampaignStatus(campaignStatus) ? (
            <strong style={{ color: "black" }}>{campaignStatus.name}</strong>
          ) : (
            <span style={{ color: "grey" }}>(unset)</span>
          )}
          <Button
            size="mini"
            icon="caret down"
            className="standard"
            style={{ marginLeft: "0.5em" }}
            onClick={handleOpen}
            disabled={disabled}
          />
        </>
      )}
      <Modal
        size="small"
        open={modalOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header
          content={
            caseId ? "Change Case Campaign Status" : "Change Campaign Status"
          }
        />
        <Modal.Content>
          <RuvixxForm
            ready={allowSubmit()}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitButtonText="APPLY"
            cancelButtonText="Cancel"
            submitButtonClassName=""
            cancelButtonClassName="basic"
          >
            <Form.Field style={{ width: "340px" }}>
              <label>Effective Date</label>
              <Datetime
                required
                closeOnSelect={true}
                onChange={moment => handleDateChange(moment)}
                value={selectedEffectiveDate}
                renderInput={props => (
                  <Input icon="large calendar outline" {...props} />
                )}
                dateFormat={"YYYY-MM-DD"}
              />
            </Form.Field>
            <Form.Group style={{ alignItems: "flex-end" }}>
              <Form.Select
                clearable
                label="Campaign Status"
                style={{ width: "340px" }}
                options={statuses.map(({ id, name }) => ({
                  key: id,
                  text: name,
                  value: id,
                }))}
                value={selectedStatusId}
                onChange={(_, { value }) => setSelectedStatusId(value || null)}
              />
            </Form.Group>
            <Form.Input
              name="reason"
              label="Converted Reason (Optional)"
              value={reason}
              onChange={(_, { value }) => setReason(value)}
            />
          </RuvixxForm>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CampaignStatusModal;

export { isValidCampaignStatus, getCampaignStatusName };
