import PropTypes from "prop-types";
import React, {
  createRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import {
  Checkbox,
  Dropdown,
  Label,
  List,
  Modal,
  Icon,
} from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import { checkIsAuthorized } from "../../components/helpers";
import RuvixxDate from "../../components/RuvixxDate";
import TagList from "../../components/TagList";
import { parseWixForm } from "../../components/wixForms/helpers";
import AuthService from "../../services/Auth";
import CampaignService from "../../services/Campaign";
import EmailTemplateService from "../../services/EmailTemplate";
import EntityService from "../../services/Entity";
import UserService from "../../services/User";
import { Popup } from "semantic-ui-react";
import { trimText } from "../../helpers/string";
import _DeleteEmailModal from "./forms/DeleteEmailModal";
import withRoleCheck from "../../components/hocs/withRoleCheck";

const DeleteEmailModal = withRoleCheck(_DeleteEmailModal, [
  ACL_RELATIONSHIPS.email.delete,
]);


class NoticesTable extends BaseTable {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.queryMethod = CampaignService.getCampaignEmails;
    this.queryArgs = [this.props.campaignId];
    this.downloadParams = { campaign_id: this.props.campaignId };
    this.responsesModal = createRef();

    this.state = {
      ...this.state,
      tableName: "notices",
      className: "CampaignTargetEmail",
      noDataText: "No Notices found. Try adjusting your filters.",
      enableSearch: true,
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.emailTags.create,
        ACL_RELATIONSHIPS.emailTags.read,
        ACL_RELATIONSHIPS.emailTags.delete,
      ]),
      filters: [],
      defaultFilterParams: {
        entity_id: this.props.entityId || undefined,
      },
      templates: [],
      users: [],
      canSendEmail: checkIsAuthorized([
        ACL_RELATIONSHIPS.campaignTargetEmail.create,
      ]),
      exportTableName: "CampaignTargetEmailsTable",
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchUsers();
      this.fetchTemplates();
      this.fetchTags();
    }
  }
  delete = async () => {
    const { checkedArr } = this.state;
    await CampaignService.deleteCampaignTargetEmails(checkedArr);
    await this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Header: props => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        Cell: ({ original: { id } }) => (
          <Checkbox
            onChange={this.handleChange}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Entity",
        accessor: "entity",
        show: !this.props.entityId,
        Cell: ({ value: entity_name, original: { entity_id } }) => (
          <Link to={`/entities/${entity_id}`}>{entity_name}</Link>
        ),
      },
      {
        Header: "Account Manager",
        accessor: "account_managers",
        sortable: false,
        headerClassName: "non-sortable",
        show: !this.props.entityId,
        Cell: props => (
          <List horizontal bulleted>
            {props.value.map(manager => (
              <List.Item key={manager.user_id}>
                {manager.user_full_name}
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Recipient ID",
        accessor: "contact_id",
        width: 110,
        Cell: props => (
          <Link to={`/contacts/${props.original.contact_id}`}>
            {props.original.contact_id}
          </Link>
        ),
      },
      {
        Header: "Recipient",
        accessor: "contact_name",
        Cell: ({ value: contact_name, original: { contact_id } }) => (
          <Link to={`/contacts/${contact_id}`}>{contact_name}</Link>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value: email, original: { contact_id } }) => (
          <Link to={`/contacts/${contact_id}`}>{email}</Link>
        ),
      },
      {
        Header: "From Address",
        accessor: "from_address",
      },
      {
        Header: "Sent By",
        accessor: "creator_user_full_name",
      },
      {
        Header: "CC",
        accessor: "info.cc",
        sortable: false,
      },
      {
        Header: "BCC",
        accessor: "info.bcc",
        sortable: false,
      },
      {
        Header: "Template",
        accessor: "template_name",
      },
      {
        Header: "Delivered",
        accessor: "metrics.first_delivered_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "First Opened",
        accessor: "metrics.first_opened_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "Last Opened",
        accessor: "metrics.last_opened_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "Manual email",
        accessor: "is_manual",
        Cell: props => {
          return (
            <div style={{ textAlign: "center", minWidth: "25px" }}>
              <Icon name={props.value ? "check" : null} />
            </div>
          );
        },
      },
      {
        Header: "Attachments",
        accessor: "attachments",
        sortable: false,
        headerClassName: "non-sortable",
        style: {
          overflow: "visible",
        },
        Cell: props => {
          if (props.value && props.value.length > 0) {
            return (
              <div style={{ overflow: "visible" }}>
                <Dropdown icon="attach">
                  <Dropdown.Menu direction="left">
                    {props.value.map(attachment => (
                      <Dropdown.Item
                        content={attachment.file_name}
                        onClick={() => window.open(attachment.url)}
                        key={attachment.url}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          } else {
            return "- -";
          }
        },
      },
      {
        Header: "Responses",
        accessor: "responses",
        sortable: false,
        headerClassName: "non-sortable",
        minWidth: 130,
        show: this.props.entityId,
        Cell: props => {
          if (props.value.length) {
            let responses = [];
            props.value.forEach(formResponse => {
              if (
                formResponse.typeform_events &&
                formResponse.typeform_events.length
              ) {
                formResponse.typeform_events.forEach(event => {
                  event.language = formResponse.language;
                  event.contact = {
                    id: props.original.contact_id,
                    email: props.original.contact_email,
                    full_name: props.original.contact_name,
                  };
                  return responses.push(event);
                });
              }
              if (
                formResponse.custom_form_events &&
                formResponse.custom_form_events.length
              ) {
                formResponse.custom_form_events.forEach(event => {
                  event.id = formResponse.id;
                  event.language = formResponse.language;
                  event.contact = {
                    id: props.original.contact_id,
                    email: props.original.contact_email,
                    full_name: props.original.contact_name,
                  };
                  return responses.push(event);
                });
              }
            });
            return (
              <div style={{ textAlign: "center" }}>
                <Label
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.responsesModal.current.open(
                      responses,
                      props.value.map(({ id }) => id)
                    );
                  }}
                >
                  Show Responses
                </Label>
              </div>
            );
          } else {
            return "- -";
          }
        },
      },
      {
        Header: "Failed At",
        accessor: "metrics.last_failed_at",
        Cell: props => {
          const value = props.value;
          if (
            props.original.metrics.last_errored_at !== undefined &&
            props.original.metrics.last_errored_at !== null
          ) {
            return <RuvixxDate date={props.original.metrics.last_errored_at} />;
          } else if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "Error Message",
        accessor: "metrics.error_message",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => {
          if (props.value) {
            return (
              <Popup
                position="top right"
                trigger={
                  <div style={{ textAlign: "center" }}>
                    {trimText(props.value, 15)}
                  </div>
                }
                content={
                  <div style={{ textAlign: "center" }}>{props.value}</div>
                }
              />
            );
          }
          return <div style={{ textAlign: "center" }}>- -</div>;
        },
      },
    ];
    this.initTableSettings(columns);
  };

  actionsDropdown() {
    const disableActions = this.state.checkedArr.length === 0;
    const disableResend = !this.state.canSendEmail;
    const manualEmails = this.state.rows
      .filter(email => email.is_manual === true)
      .map(email => email.id);
    const onlyManuals = this.state.checkedArr.every(checkedEmail =>
      manualEmails.includes(checkedEmail)
    );
    return (
      <Dropdown
        text="Actions"
        className="button mini"
        disabled={disableActions}
      >
        <Dropdown.Menu direction="left">
          <Dropdown.Item
            content="Resend"
            onClick={this.resend}
            disabled={disableResend}
          />
          <DeleteEmailModal
            onConfirmDelete={this.delete}
            disabled={!onlyManuals}
            trigger={<Dropdown.Item content="Delete" />}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  updateFilterOptions = async () => {
    const { users, templates, tags } = this.state;

    let filters = [
      {
        key: "account_manager_id",
        title: "Account Manager",
        type: "select",
        data: users,
      },
      {
        key: "template_id",
        title: "Template",
        type: "select",
        data: templates,
      },
      {
        title: "Is Manual",
        key: "grid_1",
        type: "grid",
        props: {
          columns: 2,
          style: { width: "50%" },
        },
        componentProps: {
          type: "checkbox",
        },
        components: [
          { key: "manual", title: "Manual" },
          { key: "not_manual", title: "Not Manual" },
        ],
      },
      {
        title: "From Address",
        key: "from_address",
        type: "input",
      },
      {
        key: "status",
        title: "Status",
        type: "select",
        data: [
          { id: "delivered", name: "Delivered" },
          { id: "opened", name: "Opened" },
          { id: "failed", name: "Failed" },
        ],
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    if (!this.props.entityId) {
      filters.push(
        {
          key: "assigned_user_id",
          title: "Assignee",
          type: "select",
          data: users,
        },
        {
          key: "entity_id",
          title: "Entity",
          type: "ruvixxSelect",
          queryFn: EntityService.getEntitiesForFilters,
        }
      );
    }
    this.setState({ filters });
  };

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = await rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));

    this.setState({ users }, this.updateFilterOptions);
  };

  fetchTemplates = async () => {
    const rawTemplates =
      await EmailTemplateService.getEmailTemplatesForFilters();
    const templates = rawTemplates.map(template => ({
      id: template.id,
      name: template.name,
    }));
    this.setState({ templates }, this.updateFilterOptions);
  };

  resend = async () => {
    const promises = [];
    const { checkedArr } = this.state;
    // TODO: replace this for api's multiple
    for (const emailId of checkedArr) {
      const email = this.state.rows.find(res => res.id === emailId);
      promises.push(
        CampaignService.sendEmail({
          campaignId: email.campaign_id,
          targetId: email.entity_id,
          contacts: [email.contact_id],
          template: email.template_id,
        })
      );
    }
    await Promise.all(promises);
    await this.fetchData(); // maybe-promise
  };

  render() {
    return (
      <>
        <ResponsesModal ref={this.responsesModal} />
        {this.renderTable()}
      </>
    );
  }
}

const ResponsesModal = forwardRef((_, ref) => {
  const [responses, setResponses] = useState();
  const [ids, setIds] = useState();
  const [contact, setContact] = useState();
  const [language, setLanguage] = useState();
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (responses, ids) => {
    setResponses(responses);
    if (responses.length > 0) {
      setLanguage(responses[0].language);
      setContact(responses[0].contact);
    }
    setIds(ids);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      size="fullscreen"
      header="Responses"
      closeIcon
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      content={
        <Modal.Content>
          {responses && responses.length > 0
            ? parseWixForm(responses[0], ids, language, contact)
            : null}
        </Modal.Content>
      }
    />
  );
});

export default NoticesTable;
