import React, { createRef } from "react";
import {
  Container,
  Segment,
  Icon,
  Checkbox,
  Dropdown,
} from "semantic-ui-react";
import _ from "lodash";

import "../../styles/table.scss";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import AuthService from "../../services/Auth";
import CustomFieldService from "../../services/CustomField";
import UpdateFieldModal from "./components/UpdateFieldModal";
import DeleteFieldModal from "./components/DeleteFieldModal";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import setPageTitle from "../../helpers/title";
import _CustomFieldConfigModal from "./components/CustomFieldConfigModal";

const CustomFieldConfigModal = withRoleCheck(_CustomFieldConfigModal, [
  ACL_RELATIONSHIPS.customFieldConfig.edit,
]);

class CustomFieldConfigTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CustomFieldService.getCustomFieldConfigsTable;

    this.updateModal = createRef();
    this.deleteModal = createRef();

    this.state = {
      ...this.state,
      header: "Custom Field Configs",
      headerIcon: "plus",
      className: "CustomFieldConfig",
      tableName: "custom_field_configs",
      noDataText: "No Custom Field Configs found. Try adjusting your filters.",
      exportTableName: "CustomFieldConfigTable",
      createButton: <CustomFieldConfigModal onSuccess={this.fetchData} />,
    };
  }

  async componentDidMount() {
    setPageTitle("Custom Field Configs");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: props => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        accessor: this.state.primaryKey,
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: props => (
          <Checkbox
            onChange={this.handleChange}
            name={props.original.name}
            id={props.value}
            checked={this.state.checked[props.value]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Label",
        accessor: "label",
      },
      {
        Header: "Field Type",
        accessor: "field_type",
        Cell: props => {
          if (props.value == "select") {
            return <p>Categorical</p>;
          } else {
            return <p>{_.startCase(props.value)}</p>;
          }
        },
      },
      {
        Header: "Model Type",
        accessor: "model_type",
      },
      {
        Header: "Required",
        accessor: "required",
        Cell: ({ original: { required } }) => (
          <Icon name={required ? "check" : "close"} />
        ),
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Field Options",
        accessor: "field_options",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ original: { field_options } }) => (
          <p>{field_options["type"]}</p>
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        minWidth: 60,
        sortable: false,
        headerClassName: "non-sortable",
        className: "action-menu",
        Cell: ({ original: row }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                icon="edit"
                className="text"
                content="Edit"
                onClick={() => this.handleUpdate(row)}
              />
              <Dropdown.Item
                icon="trash"
                content="Delete"
                onClick={() => this.handleDelete(row)}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  handleUpdate = row => {
    const custom_field_config = {
      id: row.id,
      label: row.label,
      model_type: row.model_type,
      field_type: row.field_type,
      field_options: row.field_options,
      description: row.description,
      required: row.required,
    };
    this.updateModal.current.open(custom_field_config);
  };

  handleDelete = row => {
    const { model_type: model_type, label, id } = row;
    this.deleteModal.current.open(model_type, label, id);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <UpdateFieldModal
          ref={this.updateModal}
          refreshTable={this.fetchData}
        />
        <DeleteFieldModal
          ref={this.deleteModal}
          refreshTable={this.fetchData}
        />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CustomFieldConfigTable;
