import React from "react";
import { Checkbox, Dropdown, Icon, List } from "semantic-ui-react";
import Link from "../../components/Link";
import "react-table-6/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import moment from "moment";
import isEqual from "lodash/isEqual";
import _ from "lodash";
import toInteger from "lodash/toInteger";

import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import RevenueOpportunityModal from "../../components/modals/RevenueOpportunityModal";
import RevenueOpportunityService from "../../services/RevenueOpportunities";
import {
  AssignUserModal as _AssignUserModal,
  ComposeNoticeModal as _ComposeNoticeModal,
  DeleteCampaignTargetModal as _DeleteCampaignTargetModal,
  EditCampaignTargetModal as _EditCampaignTargetModal,
  NewCampaignTargetModal as _NewCampaignTargetModal,
} from "./components/Modals";
import _CampaignStatusModal from "./components/CampaignStatusModal";
import FormResponsesListModal from "./components/FormResponsesListModal";
import TagList from "../../components/TagList";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import RuvixxDate from "../../components/RuvixxDate";
import { checkIsAuthorized } from "../../components/helpers";
import AuthService from "../../services/Auth";
import CampaignService from "../../services/Campaign";
import EmailTemplateService from "../../services/EmailTemplate";
import UserService from "../../services/User";
import EntityService from "../../services/Entity";
import ACL_RELATIONSHIPS from "../../acl-relationships";

import tablesSettings from "constants/Table";

const AssignUserModal = withRoleCheck(_AssignUserModal, [
  ACL_RELATIONSHIPS.campaignTargetAssign.create,
]);
const ComposeNoticeModal = withRoleCheck(_ComposeNoticeModal, [
  ACL_RELATIONSHIPS.campaignActionEmail.create,
]);

const NewCampaignTargetModal = withRoleCheck(_NewCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.create,
]);
const EditCampaignTargetModal = withRoleCheck(_EditCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.edit,
]);
const DeleteCampaignTargetModal = withRoleCheck(_DeleteCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.delete,
]);
const CampaignStatusModal = withRoleCheck(_CampaignStatusModal, [
  ACL_RELATIONSHIPS.campaignTargetChangeStatus.edit,
]);

class CampaignTargetsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CampaignService.getCampaignTargets;
    this.queryArgs = [this.props.campaignId];
    this.downloadParams = { campaign_id: this.props.campaignId };

    this.state = {
      ...this.state,
      tableName: "target_companies",
      checkedKey: "entity_id",
      users: [],
      templates: [],
      revenueOpportunityStatuses: [],
      campaignStatuses: [],
      createButton: (
        <NewCampaignTargetModal
          fetchCampaignTargets={this.onUpdate}
          campaignId={parseInt(this.props.campaignId)}
          campaign={this.props.campaign}
        />
      ),
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.entityTags.create,
        ACL_RELATIONSHIPS.entityTags.read,
        ACL_RELATIONSHIPS.entityTags.delete,
      ]),
      tags: props.tags,
      className: "Entity",
      secondaryClassName: "CampaignTarget",
      primaryKey: "entity_id",
      enableCustomFieldsSettings: true,
      exportTableName: "CampaignTargetsTable",
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchUsers();
      this.fetchTemplates();
      this.fetchCampaignStatuses();
      this.fetchCustomFields(this.props.campaignId);
      this.fetchRevenueOpportunityStatuses();
    }
  }

  componentDidUpdate(prevProps) {
    const { refresh, tags } = this.props;
    if (!isEqual(prevProps.tags, tags)) {
      this.setState({ tags }, this.updateFilterOptions);
    }
    if (prevProps.refresh !== refresh) {
      this.fetchData();
    }
  }

  onUpdate = async () => {
    this.fetchData();
    this.fetchCustomFields(this.props.campaignId);
  };

  setColumns = () => {
    const columns = [
      {
        Header: props => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        accessor: this.state.primaryKey,
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: props => (
          <Checkbox
            onChange={this.handleChange}
            name={props.original.entity_name}
            id={props.value}
            checked={this.state.checked[props.value]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => (
          <Link
            to={`/campaigns/${this.props.campaignId}/target/${props.original.entity_id}`}
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "centered non-sortable",
        className: "centered",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.entity_id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Entity",
        accessor: "entity_name",
        Cell: props => (
          <Link
            to={`/campaigns/${this.props.campaignId}/target/${props.original.entity_id}`}
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Entity Alternate Names",
        accessor: "entity_alternate_names",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {value.map(({ name }) => (
              <List.Item>{name}</List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Notice Status",
        accessor: "notice_status",
      },
      {
        Header: "# Delivered",
        accessor: "metrics.total_delivered",
      },
      {
        Header: "# Opened",
        accessor: "metrics.total_opened",
      },
      {
        Header: "# Responded",
        accessor: "metrics.total_responded",
      },
      {
        Header: "First Delivered",
        accessor: "metrics.first_delivered_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "Last Delivered",
        accessor: "metrics.last_delivered_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "First Opened",
        accessor: "metrics.first_opened_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "Last Opened",
        accessor: "metrics.last_opened_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return null;
        },
      },
      {
        Header: "Last Delivered Template",
        accessor: "metrics.last_delivered_template",
      },
      {
        Header: "Responses",
        accessor: "entity_id",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => {
          if (!!props.original.metrics.total_responded) {
            return (
              <FormResponsesListModal
                campaignId={parseInt(this.props.campaignId)}
                entityId={props.value}
              />
            );
          }
          return "- -";
        },
      },
      {
        Header: "Last Response Date",
        accessor: "last_responded_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return <RuvixxDate date={value} />;
          }
          return "- -";
        },
      },
      {
        Header: "Campaign Status",
        accessor: "campaign_status",
        minWidth: tablesSettings.statusColumnMinWidth,
        className: "centered",
        Cell: ({
          value,
          original: { campaign_id, entity_id, effective_date },
        }) => (
          <CampaignStatusModal
            campaignStatus={value}
            campaignId={campaign_id || this.props.campaignId}
            entityId={entity_id}
            updateData={this.fetchData}
            effectiveDate={effective_date}
          />
        ),
      },
      {
        Header: "Effective Date",
        accessor: "effective_date",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return "N/A";
        },
      },
      {
        Header: "Campaign Status Last Updated",
        accessor: "status_last_updated_at",
        minWidth: 120,
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return "N/A";
        },
      },
      {
        Header: "Converted Reason",
        accessor: "info.converted_reason",
      },
      {
        Header: "Account Managers",
        accessor: "account_managers",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <List horizontal bulleted>
            {props.value.map(manager => (
              <List.Item key={manager.user_id}>
                {manager.user_full_name}
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Assignee",
        accessor: "assigned_full_name",
        sortable: false,
        headerClassName: "non-sortable",
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        headerClassName: "non-sortable",
        minWidth: 130,
        className: "action-menu",
        Cell: ({ original }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCampaignTargetModal
                fetchCampaignTargets={this.onUpdate}
                campaignId={original.campaign_id}
                entityId={original.entity_id}
                menuTrigger
              />
              <DeleteCampaignTargetModal
                onConfirmDelete={() =>
                  this.deleteCampaignTarget(original.campaign_id, [
                    original.entity_id,
                  ])
                }
                menuTrigger
                modalMessage="Are you sure you want to delete this Campaign Target?"
                buttonText="Delete"
              />
              <RevenueOpportunityModal
                campaignId={original.campaign_id}
                entityId={original.entity_id}
                onSuccess={this.fetchData}
                tableDropdown={true}
              />
              <Dropdown.Item
                as={Link}
                to={{
                  pathname: `target/${original.entity_id}`,
                  state: {
                    campaign_target_name: original.entity_name,
                    campaign_name: this.props.campaign.name,
                  },
                }}
              >
                <Icon name="eye" />
                View
              </Dropdown.Item>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  actionsDropdown() {
    const disableActions = this.state.checkedArr.length === 0;
    return (
      <Dropdown
        text="Actions"
        className="button mini"
        disabled={disableActions}
      >
        <Dropdown.Menu direction="left">
          <ComposeNoticeModal
            campaignId={parseInt(this.props.campaignId)}
            campaignTargets={this.state.checkedArr}
            onSuccess={this.fetchData}
            emailService={this.props.campaign.email_service}
          />
          <AssignUserModal
            campaignId={parseInt(this.props.campaignId)}
            campaignTargets={this.state.checkedArr}
            fetchCampaignTargets={this.fetchData}
            assignedUserId={
              this.state.checkedArr.length === 1
                ? this.state.rows.find(
                    campaignTarget =>
                      campaignTarget.entity_id === this.state.checkedArr[0]
                  )?.assigned_user_id
                : null
            }
          />
          <CampaignStatusModal
            campaignId={this.props.campaignId}
            trigger={<Dropdown.Item content={"Change Campaign Status"} />}
            entities={this.state.rows.filter(({ entity_id }) =>
              _(this.state.checked)
                .toPairs()
                .filter("1")
                .map("0")
                .map(toInteger)
                .includes(entity_id)
            )}
            updateData={this.fetchData}
            effectiveDate={this.props.effectiveDate}
          />
          <DeleteCampaignTargetModal
            onConfirmDelete={() =>
              this.deleteCampaignTargets(
                this.props.campaignId,
                this.state.checkedArr,
                true
              )
            }
            menuTrigger
            modalMessage="Are you sure you want to delete these Campaign Targets?"
            noIcon
            buttonText="Remove from Campaign"
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  updateFilterOptions = async () => {
    const {
      users,
      templates,
      tags,
      campaignStatuses,
      revenueOpportunityStatuses,
      customFields,
    } = this.state;
    const { campaignId } = this.props;
    let filters = [
      {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: EntityService.getEntitiesForFilters,
        props: {
          campaignIds: [campaignId],
        },
      },
      {
        key: "assigned_user_id",
        title: "Assignee",
        type: "ruvixxSelect",
        queryFn: UserService.getUsersForFilters,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "effective_date",
        title: "Effective Date",
        type: "dateRange",
      },
      {
        key: "notice_status",
        title: "Notice Status",
        type: "select",
        data: [
          { id: "delivered", name: "Delivered" },
          { id: "opened", name: "Opened" },
          { id: "failed", name: "Errored" },
        ],
      },
      {
        key: "last_delivered_template_id",
        title: "Last Delivered Template",
        type: "select",
        data: templates,
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "campaign_status",
        title: "Campaign Status",
        type: "select",
        data: campaignStatuses,
      },
      {
        key: "revenue_opportunity_status_id",
        title: "Revenue Opportunity Status",
        type: "select",
        data: revenueOpportunityStatuses,
      },
      {
        key: "account_manager_id",
        title: "Account Manager",
        type: "ruvixxSelect",
        queryFn: UserService.getUsersForFilters,
        props: {
          includeNoneOption: true,
        },
      },
    ];
    const defaultFilterList = [];
    filters.forEach(f => {
      defaultFilterList.push(f.title);
    });
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        if (!defaultFilterList.includes(field)) {
          filters.push({
            key: `search:custom:${cf.model}:${field}`,
            title: field,
            type: "input",
            model: cf.model,
          });
        }
      })
    );
    this.setState({ filters });
  };

  fetchCampaignStatuses = async () => {
    const rawStatuses = await CampaignService.getCampaignStatuses();
    let campaignStatuses = rawStatuses.map(({ id, name }) => ({
      id,
      name,
    }));
    campaignStatuses.unshift({ id: "None", name: "None" });
    this.setState({ campaignStatuses }, this.updateFilterOptions);
  };

  fetchRevenueOpportunityStatuses = async () => {
    const revenueOpportunityStatuses = (
      await RevenueOpportunityService.getRevenueOpportunityStatusesForFilters()
    ).map(({ id, name }) => ({ id, name }));
    revenueOpportunityStatuses.unshift({ id: "None", name: "None" });
    this.setState({ revenueOpportunityStatuses }, this.updateFilterOptions);
  };

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = await rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));

    this.setState({ users }, this.updateFilterOptions);
  };

  fetchTemplates = async () => {
    const rawTemplates =
      await EmailTemplateService.getEmailTemplatesForFilters();
    const templates = rawTemplates.map(template => ({
      id: template.id,
      name: template.name,
    }));
    this.setState({ templates }, this.updateFilterOptions);
  };

  deleteCampaignTarget = async (campaignId, entityId) => {
    await CampaignService.deleteCampaignTarget(campaignId, entityId);
    this.fetchData(); // don't await, it must occur after delete modal closes
  };

  deleteCampaignTargets = async (campaignId, entityIds) => {
    await CampaignService.deleteCampaignTargets(campaignId, entityIds);
    this.fetchData(); // don't await, it must occur after delete modal closes
  };

  fetchCustomFields = async campaignId => {
    const customFields = await CampaignService.getCampaignTargetsCustomFields(
      campaignId
    );
    const entitiesCustomFields =
      await CampaignService.getTargettedEntitiesCustomFields(campaignId);
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            headerPrefix: "Campaign Target",
            fields: customFields,
            model: "CampaignTarget",
          },
          {
            accessorPrefix: "entity_custom_fields",
            headerPrefix: "Entity",
            fields: entitiesCustomFields,
            model: "Entity",
          },
        ],
      },
      this.updateFilterOptions
    );
  };
}

export default CampaignTargetsTable;
