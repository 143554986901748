import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  MenuItem,
  Button,
  Icon,
  Select,
  Checkbox,
  Dropdown,
} from "semantic-ui-react";
import BaseTableV2 from "../../components/BaseTableV2/BaseTableV2";
import TableHeader from "../../components/BaseTableV2/TableHeader";
import Link from "../../components/Link";
import DraggableTab from "../../components/DraggableTab";
import store from "store";
import CampaignService from "../../services/Campaign";
import EmailTemplateService from "../../services/EmailTemplate";
import UserService from "../../services/User";
import TagService from "../../services/Tag";
import CallDispositionService from "../../services/CallDispositions";
import {
  AssignUserModal as _AssignUserModal,
  ComposeNoticeModal as _ComposeNoticeModal,
  DeleteCampaignTargetModal as _DeleteCampaignTargetModal,
  EditCampaignTargetModal as _EditCampaignTargetModal,
  NewCampaignTargetModal as _NewCampaignTargetModal,
} from "./components/Modals";
import { NewNoteModal as _NewNoteModal } from "./../../components/modals/NoteModals";
import { DEFAULT_PAGE_SIZE, EPOCH_TIMESTAMP } from "../../constants/Constants";

import _CampaignStatusModal, {
  getCampaignStatusName,
} from "./components/CampaignStatusModal";

import withRoleCheck from "../../components/hocs/withRoleCheck";
import { checkIsAuthorized, tableTimestamp } from "../../components/helpers";
import ACL_RELATIONSHIPS from "../../acl-relationships";

import "../../components/BaseTableV2/BaseTableV2.scoped.scss";
import "./PipelineView.scoped.scss";

const AssignUserModal = withRoleCheck(_AssignUserModal, [
  ACL_RELATIONSHIPS.campaignTargetAssign.create,
]);
const ComposeNoticeModal = withRoleCheck(_ComposeNoticeModal, [
  ACL_RELATIONSHIPS.campaignActionEmail.create,
]);

const NewCampaignTargetModal = withRoleCheck(_NewCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.create,
]);
const EditCampaignTargetModal = withRoleCheck(_EditCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.edit,
]);
const DeleteCampaignTargetModal = withRoleCheck(_DeleteCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.delete,
]);
const CampaignStatusModal = withRoleCheck(_CampaignStatusModal, [
  ACL_RELATIONSHIPS.campaignTargetChangeStatus.edit,
]);

const NewNoteModal = withRoleCheck(_NewNoteModal, [
  ACL_RELATIONSHIPS.activity.create,
]);

const PipelineTable = ({
  campaignId,
  columns,
  queryMethod,
  queryFilters,
  onUpdateQueryFilters,
  header,
  queryKey,
  itemActions,
  actions,
  shouldRefetch,
  onFetched,
  onSelectedRows,
}) => {
  return (
    <BaseTableV2
      columns={columns}
      queryMethod={queryMethod}
      queryArgs={[campaignId]}
      queryFilters={queryFilters}
      onUpdateQueryFilters={onUpdateQueryFilters}
      queryKey={queryKey}
      config={{
        enableHeader: false,
        enableFooter: true,
        enablePagination: true,
        pageSize: DEFAULT_PAGE_SIZE,
      }}
      header={header}
      headerIcon="edit"
      actions={actions}
      shouldRefetch={shouldRefetch}
      onFetched={onFetched}
      onSelectedRows={onSelectedRows}
    />
  );
};

const PipelineView = ({
  campaignId,
  toggleView,
  campaign,
  isPipelineEnabled,
  onTogglePipelineView,
  campaignStatusPreset,
}) => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [campaignStatuses, setCampaignStatuses] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [tags, setTags] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [callDispositions, setCallDispositions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [addingStage, setAddingStage] = useState(false);
  const [pipelineStages, setPipelineStages] = useState([
    { name: "Total", index: 0 },
  ]);
  const [campaignStatusOptions, setCampaignStatusOptions] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [stages, setStages] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      Header: "Entity",
      accessor: "entity_name",
      Cell: props => (
        <Link to={`target/${props.row.original.entity_id}`}>{props.value}</Link>
      ),
    },
    {
      Header: "Last Delivered Template",
      accessor: "metrics.last_delivered_template",
    },
    {
      Header: "Last Delivered Email Date",
      accessor: "metrics.last_delivered_at",
      Cell: ({ value }) => tableTimestamp(value),
    },
    {
      Header: "Last Call Date",
      accessor: "last_called_at",
      Cell({ value }) {
        if (value === EPOCH_TIMESTAMP) {
          return null;
        }
        return tableTimestamp(value);
      },
    },
    {
      Header: "Status Last Updated",
      accessor: "status_last_updated_at",
      Cell: ({ value }) => tableTimestamp(value),
    },
    {
      Header: "Last Call Disposition",
      accessor: "last_call_disposition_id",
      Cell: ({ value }) => {
        let disposition = callDispositions.find(d => d.id == value);
        if (!disposition) {
          return null;
        }
        return disposition.name;
      },
    },
    {
      Header: "Last Note Date",
      accessor: "last_note_at",
      Cell: ({ value }) => tableTimestamp(value),
    },
    {
      Header: "Actions",
      className: "actions",
      Cell: props => {
        const { original } = props.row;
        return (
          <>
            <EditCampaignTargetModal
              fetchCampaignTargets={handleRefetch}
              campaignId={original.campaign_id}
              entityId={original.entity_id}
              iconTrigger
            />

            <Link
              to={{
                pathname: `target/${original.entity_id}`,
                state: {
                  campaign_target_name: original.entity_name,
                  campaign_name: campaign.name,
                },
              }}
            >
              <Icon name="eye" />
            </Link>
            <DeleteCampaignTargetModal
              onConfirmDelete={() =>
                deleteCampaignTarget(original.campaign_id, original.entity_id)
              }
              modalMessage="Are you sure you want to delete this Campaign Target?"
              buttonText="Delete"
              iconTrigger
            />
          </>
        );
      },
    },
  ];

  const [queryFilters, setQueryFilters] = useState({});
  const handleUpdateQueryFilters = updatedFilters => {
    setQueryFilters(updatedFilters);
  };

  const handleTabChange = (_, { activeIndex }) => {
    if (activeIndex >= pipelineStages.length) {
      return;
    }
    setTabActiveIndex(activeIndex);
  };

  const handleRefetch = () => {
    setShouldRefetch(true);
  };

  const handleFetched = (stageName, apiResponse) => {
    if (apiResponse) {
      setPipelineStages(prevPipelineStages => {
        const { total } = apiResponse;
        let _pipelineStages = [
          ...prevPipelineStages.map(stage => {
            let _stage = { ...stage };
            if (_stage.name === stageName) {
              _stage.totalResults = total;
            }
            return _stage;
          }),
        ];
        return _pipelineStages;
      });
    }
    setShouldRefetch(false);
  };

  const handleSelectedRows = useCallback(
    rows => {
      setSelectedRows(rows);
    },
    [setSelectedRows]
  );

  const deleteCampaignTarget = async (campaignId, campaignTargetId) => {
    await CampaignService.deleteCampaignTarget(campaignId, campaignTargetId);
    handleRefetch();
  };

  const fetchCampaignStatuses = useCallback(async () => {
    const campaignStatuses = await CampaignService.getCampaignStatuses();
    setCampaignStatuses(campaignStatuses);
  });

  const fetchUsers = useCallback(async () => {
    const rawUsers = await UserService.getUsers();
    const assignees = await rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));

    setAssignees(assignees);
  });

  const fetchTemplates = useCallback(async () => {
    const rawTemplates =
      await EmailTemplateService.getEmailTemplatesForFilters();
    const templates = rawTemplates.map(template => ({
      id: template.id,
      name: template.name,
    }));
    setTemplates(templates);
  });

  const fetchCallDispositions = useCallback(async () => {
    const callDispositions =
      await CallDispositionService.getDispositionsForFilters();
    setCallDispositions(callDispositions);
  });

  const handleUpdateFilterOptions = useCallback(() => {
    const filterOptions = [
      {
        // TODO: Make RuvixxSelect usable in filters
        key: "search:entity_name",
        title: "Entity Name",
        type: "input",
      },
      {
        key: "assigned_user_id",
        title: "Assignee",
        type: "select",
        data: assignees,
      },
      {
        key: "notice_status",
        title: "Notice Status",
        type: "select",
        data: [
          { id: "delivered", name: "Delivered" },
          { id: "opened", name: "Opened" },
          { id: "failed", name: "Errored" },
        ],
      },
      {
        key: "last_delivered_template_id",
        title: "Last Delivered Template",
        type: "select",
        data: templates,
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      /*
      {
        key: "campaign_status",
        title: "Campaign Status",
        type: "select",
        data: campaignStatuses,
      },
      */
    ];
    setFilterOptions(filterOptions);
  }, [assignees, templates, tags]); //, campaignStatuses]);

  const fetchCustomFields = useCallback(async () => {
    const customFields = await CampaignService.getCampaignTargetsCustomFields(
      campaignId
    );
    const entitiesCustomFields =
      await CampaignService.getTargettedEntitiesCustomFields(campaignId);
    setCustomFields([
      {
        accessorPrefix: "custom_fields",
        headerPrefix: "Campaign Target",
        fields: customFields,
      },
      {
        accessorPrefix: "entity_custom_fields",
        headerPrefix: "Entity",
        fields: entitiesCustomFields,
      },
    ]);
  });

  const getPipelineSettings = () => {
    const settings = store.get("userAuth").pipeline_settings;
    const pipelineSettings = !!settings ? settings[campaignId] || {} : {};
    // TODO: read presets
    setStages(
      (pipelineSettings.stages?.length > 0 && pipelineSettings.stages) ||
        campaignStatusPreset?.map((campaignStatus, index) => ({
          id: campaignStatus.id,
          index: index + 1,
        })) ||
        []
    );
  };

  const setPipelineSettings = (campaignId, settings) => {
    Object.keys(settings).forEach(settingName => {
      UserService.updateUserPipelineSettings(
        campaignId,
        settingName,
        settings[settingName]
      );
    });
  };

  const savePipelineStages = (campaignId, pipelineStages) => {
    setPipelineSettings(campaignId, {
      stages: pipelineStages
        .filter(stage => !!stage.id)
        .map((stage, index) => ({ id: stage.id, index: index + 1 })),
    });
  };
  const handleUpdateStatusOptions = useCallback(() => {
    const pipelineStagesIds = pipelineStages.map(cs => cs.id);
    const campaignStatusOptions = campaignStatuses
      .filter(campaignStatus => !pipelineStagesIds.includes(campaignStatus.id))
      .map(campaignStatus => {
        return {
          key: campaignStatus.id,
          value: campaignStatus.id,
          text: campaignStatus.name,
        };
      });
    setCampaignStatusOptions(campaignStatusOptions);
  }, [pipelineStages, campaignStatuses]);

  const handleUpdatePipelineStages = useCallback(() => {
    setPipelineStages(prevPipelineStages => {
      // Get existent pipeline stages with all data first to keep counters
      let _pipelineStages = [
        ...prevPipelineStages.filter(
          pipelineStage =>
            !!stages.find(stage => stage.id === pipelineStage.id) ||
            pipelineStage.name === "Total"
        ),
        ...campaignStatuses.filter(
          campaignStatus =>
            !!stages.find(stage => stage.id === campaignStatus.id) &&
            !pipelineStages.find(
              pipelineStage => pipelineStage.id === campaignStatus.id
            )
        ),
      ]
        .map(pipelineStage => ({
          ...pipelineStage,
          index: stages.find(stage => pipelineStage.id === stage.id)?.index,
        }))
        .sort((a, b) => a.index - b.index);
      return _pipelineStages;
    });

    handleUpdateStatusOptions();
  }, [campaignStatuses]);

  const handleUpdateTabOrder = (key, sIndex, dIndex) => {
    if (sIndex === 0 || dIndex === 0) {
      return; // Don't allow to move the "total" stage
    }
    if (dIndex >= pipelineStages.length) {
      // When dragged all the way to the end set as last
      dIndex = pipelineStages.length - 1;
    }
    let _pipelineStages = [...pipelineStages];
    let stage = _pipelineStages.splice(sIndex, 1);
    _pipelineStages.splice(dIndex, 0, ...stage);
    setPipelineStages(_pipelineStages);
    savePipelineStages(campaignId, _pipelineStages);
  };

  const handleSelectStage = (_, { value }) => {
    value = value.length > 0 && value[0];
    setAddingStage(false);

    if (!value) return;
    const campaignStatus = campaignStatuses.find(
      campaignStatus => campaignStatus.id === value
    );
    const _pipelineStages = [...pipelineStages, campaignStatus];
    setPipelineStages(_pipelineStages);
    savePipelineStages(campaignId, _pipelineStages);
  };

  const handleRemoveStage = stageName => {
    const _pipelineStages = pipelineStages.filter(
      stage => stage.name != stageName
    );
    setPipelineStages(_pipelineStages);
    savePipelineStages(campaignId, _pipelineStages);
  };

  const handleSearch = value => {
    let _queryFilters = { ...queryFilters };
    _queryFilters.search = value;
    setQueryFilters(_queryFilters);
  };

  const getTabPanes = () => {
    const tabPanes = [
      ...pipelineStages.map((stage, index) => {
        const key = stage.name.toLowerCase();
        const isTotalStage = stage.name === "Total";
        return {
          menuItem: (
            <MenuItem
              key={key}
              onMouseEnter={() => {
                setHoveredMenuItem(key);
              }}
              onMouseLeave={() => {
                setHoveredMenuItem(null);
              }}
              isDragDisabled={key === "total"}
            >
              {stage.name}{" "}
              <Button
                icon
                onClick={
                  !isTotalStage ? () => handleRemoveStage(stage.name) : () => {}
                }
              >
                {hoveredMenuItem === key && !isTotalStage ? (
                  <Icon name="minus" />
                ) : (
                  <>{stage.totalResults || 0}</>
                )}
              </Button>
            </MenuItem>
          ),
          pane: {
            key: key,
            content: (
              <PipelineTable
                queryKey={stage.name.toLowerCase()}
                header={stage.name}
                campaignId={campaignId}
                columns={columns}
                queryMethod={CampaignService.getCampaignTargets}
                queryFilters={
                  stage.id
                    ? { ...queryFilters, campaign_status: stage.id }
                    : queryFilters
                }
                onUpdateQueryFilters={handleUpdateQueryFilters}
                shouldRefetch={shouldRefetch}
                onFetched={apiResponse =>
                  handleFetched(stage.name, apiResponse)
                }
                onSelectedRows={handleSelectedRows}
              />
            ),
          },
        };
      }),
      {
        menuItem: (
          <Dropdown
            inline
            clearable
            search
            selection
            multiple
            placeholder="Select campaign status"
            className={`item ${addingStage ? "" : "hide"}`}
            onChange={handleSelectStage}
            options={campaignStatusOptions}
            isDragDisabled={true}
            icon="minus"
            open={addingStage}
            onClose={() => {
              setAddingStage(false);
            }}
          />
        ),
      },
      {
        menuItem: (
          <div
            className={`add ${addingStage ? "hide" : ""}`}
            isDragDisabled={true}
          >
            <Button
              icon
              size="mini"
              onClick={() => {
                setAddingStage(!addingStage);
              }}
            >
              <Icon name={`${addingStage ? "minus" : "plus"}`} />
            </Button>
          </div>
        ),
      },
    ];
    return tabPanes;
  };

  useEffect(() => {
    getPipelineSettings();
    fetchCampaignStatuses();
    fetchCallDispositions();
    fetchUsers();
    fetchTemplates();
    fetchCustomFields();
  }, []);

  useEffect(() => {
    handleUpdateFilterOptions();
  }, [handleUpdateFilterOptions]);

  useEffect(() => {
    handleUpdatePipelineStages();
  }, [handleUpdatePipelineStages]);

  useEffect(() => {
    handleUpdateStatusOptions();
  }, [handleUpdateStatusOptions]);

  const getActions = () => {
    return (
      <>
        <ComposeNoticeModal
          campaignId={parseInt(campaignId)}
          campaignTargets={selectedRows.map(row => row.entity_id)}
          onSuccess={handleRefetch}
          emailService={campaign.email_service}
        />
        <AssignUserModal
          campaignId={parseInt(campaignId)}
          campaignTargets={selectedRows.map(row => row.id)}
          fetchCampaignTargets={handleRefetch}
          assignedUserId={
            selectedRows.length === 1 ? selectedRows[0].assigned_user_id : null
          }
        />
        <CampaignStatusModal
          campaignId={campaignId}
          trigger={<Dropdown.Item content={"Change Campaign Status"} />}
          entities={selectedRows}
          updateData={handleRefetch}
        />
        {selectedRows.length === 1 && (
          <NewNoteModal
            modelType="Entity"
            modelId={parseInt(selectedRows[0].entity_id)}
            trigger={<Dropdown.Item content={"Add a note"} />}
          />
        )}
      </>
    );
  };

  return (
    <div className="pipeline">
      <TableHeader
        enableSearch={true}
        enableAdvancedSearch={false}
        filterOptions={filterOptions}
        onSearch={handleSearch}
        onRefetch={handleRefetch}
        createButton={
          <NewCampaignTargetModal
            fetchCampaignTargets={handleRefetch}
            campaignId={parseInt(campaignId)}
            campaign={campaign}
          />
        }
        tags={tags}
        queryFilters={queryFilters}
        onUpdateQueryFilters={handleUpdateQueryFilters}
        tableLeft={
          <Checkbox
            toggle
            className="pipeline"
            name="toggle_pipeline"
            checked={isPipelineEnabled}
            onChange={onTogglePipelineView}
            label="Pipeline View"
          />
        }
        actions={getActions()}
        disableActions={selectedRows.length === 0}
      />
      <DraggableTab
        panes={getTabPanes()}
        activeIndex={tabActiveIndex}
        onTabChange={handleTabChange}
        renderActiveOnly={false}
        onUpdateTabOrder={handleUpdateTabOrder}
      />
    </div>
  );
};

export default PipelineView;
