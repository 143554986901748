import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import CallDispositionForm from "./CallDispositionForm";

export default function CallDispositionModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    props.onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        props.button ? (
          <Button
            content="New Disposition"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={props.dispositionId ? "Edit" : "New Disposition"}
            icon={props.dispositionId ? "edit" : null}
            onClick={handleOpen}
          />
        )
      }
    >
      <Header
        content={props.dispositionId ? "Edit Disposition" : "New Disposition"}
      />
      <Modal.Content>
        <CallDispositionForm
          onSuccess={onSuccess}
          dispositionId={props.dispositionId}
          parentDispositions={props.parentDispositions}
          dispositions={props.dispositions}
        />
      </Modal.Content>
    </Modal>
  );
}
