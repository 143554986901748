import React, { useState } from "react";
import { Button, Dropdown, Header, Icon, Modal } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "./../../styles/campaign.scss";
import "./../../styles/table.scss";
import PropTypes from "prop-types";

const FormModal = ({
  onSuccess,
  modelType,
  size,
  FormComponent,
  model,
  formProps,
  actionText,
  dropDownElement,
  button,
  icon,
  dropdown,
  customTrigger,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = async () => {
    onSuccess();
    handleClose();
  };

  const defaultActionText = actionText
    ? actionText
    : model?.id
    ? `Edit`
    : `New ${modelType}`;
  const iconName = icon ? icon : model?.id ? "edit" : null;

  let trigger;
  if (dropdown) {
    trigger = (
      <Dropdown.Item
        onClick={handleOpen}
        content={dropDownElement || defaultActionText}
        icon={!dropDownElement && iconName}
      />
    );
  } else if (icon) {
    trigger = (
      <Icon
        name={icon}
        className="primary"
        link
        onClick={handleOpen}
        style={{
          verticalAlign: this.props.dialer ? "-20px" : "-2px",
        }}
      />
    );
  } else {
    trigger = (
      <Button
        content={defaultActionText}
        onClick={handleOpen}
        size="tiny"
        className="item-adder"
      />
    );
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size={size || "small"}
      closeIcon
      trigger={customTrigger || trigger}
      onFocus={e => {
        e.stopPropagation();
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Header content={defaultActionText} />
      <Modal.Content>
        <FormComponent onSuccess={handleSuccess} model={model} {...formProps} />
      </Modal.Content>
    </Modal>
  );
};

FormModal.propTypes = {
  FormComponent: PropTypes.elementType.isRequired,
  onSuccess: PropTypes.func.isRequired,
  modelType: PropTypes.string,
};

export default FormModal;
