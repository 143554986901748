import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import RoleForm from "./RoleForm";

const RoleModal = forwardRef(({ onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [role, setRole] = useState();
  const [copyMode, setCopyMode] = useState(false);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (role, copyMode = false) => {
    setRole(role);
    setCopyMode(copyMode);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    onSuccess();
  };

  return (
    <Modal
      size="large"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      className="role-modal"
    >
      <Modal.Header
        content={`${!role ? "New" : copyMode ? "Clone" : "Edit"} Role`}
      />
      <Modal.Content>
        <RoleForm
          role={role}
          onSuccess={handleSuccess}
          copyMode={copyMode}
          onClose={handleClose}
        />
      </Modal.Content>
    </Modal>
  );
});

export default RoleModal;
