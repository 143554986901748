import React from "react";
import "react-phone-number-input/style.css";
import { Button, Checkbox, Icon, Input, Table } from "semantic-ui-react";
import "./PhoneNumbers.scoped.scss";
import { setEveryItemPropertyAs } from "components/helpers";
// TODO: Use EmailAddresses.scoped.scss??

const blankEmail = {
  email: "",
  is_primary: false,
  enabled: true,
  subscribed: true,
};

function EmailAddresses({
  emailAddresses,
  addEmailAddress,
  removeEmailAddress,
  updateEmailAddress,
  simple,
}) {
  const handleRemoveEmail = index => {
    removeEmailAddress(index);
  };

  const handleAddEmail = () => {
    addEmailAddress({ ...blankEmail });
  };

  const handleEmailEdit = (index, data) => {
    let emailsCopy = [...emailAddresses];
    let propName = data.name;
    emailsCopy[index][propName] =
      data.type === "checkbox" ? data.checked : data.value;

    if (propName === "is_primary" && data.checked) {
      setEveryItemPropertyAs(emailsCopy, propName, false, index);
    }

    updateEmailAddress(emailsCopy[index], index);
  };

  const emailable = emailAddresses.some(
    ({ enabled, subscribed }) => enabled && subscribed
  );

  return (
    <React.Fragment>
      {emailAddresses !== undefined &&
      emailAddresses !== null &&
      emailAddresses.length > 0 ? (
        <div className="phone-numbers-list">
          <p className={`${emailable ? "some" : "none"} enabled-label`}>
            {emailable ? "" : "Not "}Emailable{" "}
            {emailable ? (
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path
                  d="M14.5 0H1.5C0.671562 0 0 0.671562 0 1.5V10.5C0 11.3284 0.671562 12 1.5 12H14.5C15.3284 12 16 11.3284 16 10.5V1.5C16 0.671562 15.3284 0 14.5 0ZM14.5 1.5V2.77516C13.7993 3.34575 12.6823 4.233 10.2942 6.10297C9.76787 6.51694 8.72538 7.51147 8 7.49988C7.27475 7.51159 6.23191 6.51678 5.70584 6.10297C3.31813 4.23328 2.20078 3.34584 1.5 2.77516V1.5H14.5ZM1.5 10.5V4.69994C2.21606 5.27028 3.23153 6.07063 4.77931 7.28263C5.46234 7.82028 6.6585 9.00719 8 8.99997C9.33491 9.00719 10.5159 7.8375 11.2204 7.28288C12.7682 6.07091 13.7839 5.27034 14.5 4.69997V10.5H1.5Z"
                  fill="#01B604"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path
                  d="M18.5017 14.2849L1.50339 0.134077C1.30735 -0.032283 1.01221 -0.0092824 0.84585 0.186758L0.543418 0.54187C0.377059 0.73791 0.400059 1.03305 0.596099 1.19941L17.5944 15.3503C17.7905 15.5166 18.0856 15.4936 18.252 15.2976L18.5544 14.9425C18.7208 14.7465 18.6978 14.4513 18.5017 14.2849Z"
                  fill="#B4B4B4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17 11.9487V3.5C17 2.67156 16.3284 2 15.5 2H4.87501L6.70313 3.5H15.5V4.77516C14.8524 5.30253 13.8491 6.1004 11.8153 7.69464L13.0033 8.66935C14.1229 7.79111 14.9124 7.16803 15.5 6.69997V10.7179L17 11.9487ZM12.7727 12.5L14.5455 14H2.5C1.67156 14 1 13.3284 1 12.5V3.5C1 3.21523 1.07935 2.94899 1.21715 2.7222L2.5 3.80768V4.77516C3.20078 5.34584 4.31813 6.23328 6.70584 8.10297C6.79424 8.1725 6.89723 8.25844 7.01085 8.35326C7.57341 8.82269 8.39662 9.50962 9 9.49988C9.06537 9.50092 9.13332 9.49379 9.20324 9.47966L10.4897 10.5682C10.0304 10.8221 9.5272 11.0028 9 11C7.85176 11.0061 6.81 10.1375 6.10535 9.5499C5.98676 9.45101 5.87771 9.36008 5.77931 9.28263C4.23153 8.07063 3.21606 7.27028 2.5 6.69994V12.5H12.7727Z"
                  fill="#B4B4B4"
                />
              </svg>
            )}
          </p>
          <Table size="small" basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                {!simple && [
                  <Table.HeaderCell width={3}>Primary?</Table.HeaderCell>,
                  <Table.HeaderCell width={3}>Enabled?</Table.HeaderCell>,
                  <Table.HeaderCell width={3}>Subscribed?</Table.HeaderCell>,
                ]}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {emailAddresses.map((row, i) => {
                return (
                  <Table.Row key={i} className={row.__editing && "editing"}>
                    <>
                      <Table.Cell>
                        <Input
                          required
                          placeholder="Email"
                          name="email"
                          value={row.email}
                          onChange={(_, data) => {
                            handleEmailEdit(i, data);
                          }}
                        />
                      </Table.Cell>
                      {!simple && [
                        <Table.Cell>
                          <Checkbox
                            toggle
                            name="is_primary"
                            checked={row.is_primary === true}
                            onChange={(_, data) => {
                              handleEmailEdit(i, data);
                            }}
                          />
                        </Table.Cell>,
                        <Table.Cell>
                          <Checkbox
                            toggle
                            name="enabled"
                            checked={row.enabled === true}
                            onChange={(_, data) => {
                              handleEmailEdit(i, data);
                            }}
                          />
                        </Table.Cell>,
                        <Table.Cell>
                          <Checkbox
                            toggle
                            name="subscribed"
                            checked={row.subscribed === true}
                            onChange={(_, data) => {
                              handleEmailEdit(i, data);
                            }}
                          />
                        </Table.Cell>,
                      ]}
                      <Table.Cell className="actions">
                        <div>
                          <Icon
                            link
                            name="cancel"
                            onClick={() => handleRemoveEmail(i)}
                            title="Cancel edit"
                          />
                        </div>
                      </Table.Cell>
                    </>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <p>No Email Addresses</p>
      )}
      <Button
        basic
        icon="plus"
        type="button"
        size="tiny"
        onClick={handleAddEmail}
        content="Add Email"
      />
    </React.Fragment>
  );
}

export default EmailAddresses;
