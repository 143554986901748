import React from "react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import { Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";

import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import FormModal from "../../components/modals/FormModal";
import setPageTitle from "../../helpers/title";
import AuthService from "../../services/Auth";
import ProductService from "../../services/Product";
import "../../styles/campaign.scss";
import "../../styles/table.scss";

import ProductForm from "./ProductForm";

const CreateProductModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.product.create,
]);
const EditProductModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.product.edit,
]);
const DeleteProductModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.product.delete,
]);

class ProductsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = ProductService.getTable;

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "Products",
      headerIcon: "shop",
      className: "Product",
      tableName: "products",
      noDataText: "No Products found. Create one.",
      createButton: (
        <CreateProductModal
          onSuccess={this.fetchData}
          modelType={"Product"}
          FormComponent={ProductForm}
        />
      ),
      playingId: undefined,
    };
  }

  async componentDidMount() {
    setPageTitle("Products");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteProduct = async id => {
    await ProductService.delete(id);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "List Price",
        accessor: "list_price",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditProductModal
                dropdown
                onSuccess={this.fetchData}
                modelType={"Product"}
                model={props.original}
                FormComponent={ProductForm}
              />
              <DeleteProductModal
                actionDescription="Delete"
                buttonColor="grey"
                onConfirm={() => this.deleteProduct(props.original.id)}
                menuTrigger
                icon="trash"
                warning
              >
                <p>Are you sure you want to delete this Product?</p>
              </DeleteProductModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default ProductsTable;
