import React from "react";
import {
  Button,
  Icon,
  Container,
  Segment,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import BaseTable from "../../components/BaseTable";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import AuthService from "../../services/Auth";
import CustomDropdown from "../../components/CustomDropdown";
import CustomEmailDomainsService from "../../services/CustomEmailDomains";
import DomainModal from "./components/DomainModal";
import setPageTitle from "../../helpers/title";

import "../../styles/table.scss";

const NewDomainModal = withRoleCheck(DomainModal, [
  ACL_RELATIONSHIPS.customEmailDomains.create,
]);
const EditDomainModal = withRoleCheck(DomainModal, [
  ACL_RELATIONSHIPS.customEmailDomains.update,
]);

const DeleteDomainModal = withRoleCheck(
  ({ domain, fetchDomains }) => {
    return (
      <ConfirmationModal
        actionDescription="Delete"
        buttonColor="grey"
        onConfirm={() => handleDeleteDomain(domain.id, fetchDomains)}
        menuTrigger
        icon="trash"
        warning
      >
        <p>Are you sure you want to delete this Custom Email Domain?</p>
      </ConfirmationModal>
    );
  },
  [ACL_RELATIONSHIPS.customEmailDomains.delete]
);

const handleDeleteDomain = async (domainId, fetchDomains) => {
  await CustomEmailDomainsService.deleteDomain(domainId);
  fetchDomains();
};

class CustomEmailDomainsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CustomEmailDomainsService.getDomains;

    this.state = {
      ...this.state,
      header: "Custom Email Domains",
      headerIcon: "mail",
      tableName: "custom_email_domains",
      noDataText: "No custom email domains found. Try adding a new domain.",
      createButton: <NewDomainModal button fetchDomains={this.fetchData} />,
    };
  }

  async componentDidMount() {
    setPageTitle("Custom Email Domains");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
  }

  handleSetAsDefault = async (domainId, isDefault) => {
    // Display checked update immediately before fetching
    this.setState({
      rows: this.state.rows.map(row => {
        row.is_default = row.id === domainId && isDefault ? isDefault : false;
        return row;
      }),
    });
    await CustomEmailDomainsService.updateDomain(domainId, { isDefault });
    await this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Domain Name",
        accessor: "name",
      },
      {
        Header: "Verified",
        accessor: "verified",
        width: 100,
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              {props.value === true ? (
                <Icon name="check" />
              ) : props.value === false ? (
                <Icon name="close" />
              ) : null}
            </div>
          );
        },
      },
      {
        Header: "Set as Default",
        width: 100,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: row }) => (
          <Checkbox
            toggle
            name={`is_default_${row.id}`}
            checked={row.is_default}
            onChange={(_, { checked }) =>
              this.handleSetAsDefault(row.id, checked)
            }
          />
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        width: 100,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: domain }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditDomainModal
                domain={domain}
                fetchDomains={this.fetchData}
                trigger={<Button icon="edit" />}
              />
              <DeleteDomainModal
                domain={domain}
                fetchDomains={this.fetchData}
                trigger={<Button icon="trash" />}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];

    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CustomEmailDomainsTable;
