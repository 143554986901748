import React, { useState } from "react";
import PropTypes from "prop-types";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

function DeleteFooterModal({ onConfirmDelete, noIcon, menuTrigger }) {
  return (
    <ConfirmationModal
      actionDescription="Delete"
      buttonColor="grey"
      onConfirm={onConfirmDelete}
      menuTrigger={menuTrigger}
      icon={noIcon ? null : "trash"}
      warning
    >
      <p>Are you sure you want to delete this Email Footer?</p>
    </ConfirmationModal>
  );
}

export default DeleteFooterModal;
