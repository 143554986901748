import React, { createRef } from "react";
import { Checkbox, Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import BaseTable from "../../../components/BaseTable";
import CustomDropdown from "../../../components/CustomDropdown";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import FormModal from "../../../components/modals/FormModal";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import GroupService from "../../../services/Group";
import UserService from "../../../services/User";
import GroupForm from "./GroupForm";
import GroupModal from "./GroupModal";

const CreateGroupModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.adminGroup.create,
]);
const EditGroupModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.adminGroup.edit,
]);
const DeleteGroupModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.adminGroup.delete,
]);

class GroupsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = GroupService.getTable;

    this.groupModal = createRef();

    this.state = {
      ...this.state,
      enableSearch: true,
      enableSettings: false,
      tableName: "groups_table",
      noDataText: "No Groups",
      userIsAdmin: false,
      createButton: (
        <CreateGroupModal
          onSuccess={this.fetchData}
          modelType={"Group"}
          FormComponent={GroupForm}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Groups");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
    this.setState({ userIsAdmin: await UserService.isAdmin() });
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        width: 40,
        headerClassName: "centered",
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
        width: 250,
      },
      //  TODO: use groups for internal users?
      // {
      //   Header: "External?",
      //   accessor: "internal",
      //   Cell: ({ value }) =>
      //     !value ? <p style={{ fontWeight: "bold" }}>YES</p> : null,
      // },
      {
        Header: "Actions",
        id: "actions",
        minWidth: 60,
        sortable: false,
        headerClassName: "non-sortable",
        className: "action-menu",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditGroupModal
                dropdown
                onSuccess={this.fetchData}
                modelType={"Group"}
                model={props.original}
                FormComponent={GroupForm}
              />
              <DeleteGroupModal
                actionDescription="Delete"
                buttonColor="grey"
                onConfirm={() => this.deleteGroup(props.original.id)}
                menuTrigger
                icon="trash"
                warning
              >
                <p>Are you sure you want to delete this Group?</p>
              </DeleteGroupModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  deleteGroup = async id => {
    await GroupService.delete(id);
    this.fetchData();
  };

  render = () => {
    return (
      <Container fluid className="route sub-route" {...this.props}>
        <GroupModal ref={this.groupModal} onSuccess={this.fetchData} />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  };
}

export default GroupsTable;
