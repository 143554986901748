import React, { Component, cloneElement } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Message,
  Modal,
  Table,
} from "semantic-ui-react";
import TableButton from "./TableButton";

class AlternateNameModal extends Component {
  static defaultProps = {
    index: null,
    trigger: (
      <Button size="tiny" basic>
        <Icon name="plus" />
        <strong>Add Alternate Name</strong>
      </Button>
    ),
  };

  constructor(props) {
    super(props);

    this.state = {
      alternateName: "",
      index: null,
      type: "",
      action: props.index === null ? "Add" : "Edit",
      error: false,
      modalOpen: false,
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      error: false,
    });
  };

  handleOpen = e => {
    const { alternateName, type, index } = this.props;
    e.preventDefault();
    this.setState({
      modalOpen: true,
      alternateName,
      type,
      index,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
      alternateName: "",
      tyoe: "",
      index: null,
    });
  };

  handleAdd = () => {
    const { alternateName } = this.state;
    this.props.updateAlternateName(alternateName);
    this.handleClose();
  };

  handleEdit = () => {
    const { alternateName, index } = this.state;
    this.props.updateAlternateName(alternateName, index);
    this.handleClose();
  };

  render() {
    const { alternateName, type, error, action, index } = this.state;

    return (
      <Modal
        size="small"
        trigger={cloneElement(this.props.trigger, { onClick: this.handleOpen })}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header content={`${action} ${type} Alternate Name`} />
        <Modal.Content style={{ paddingBottom: 0 }}>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                label="Alternate Name"
                name="alternateName"
                fluid
                value={alternateName}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Message
              visible={error}
              hidden={!error}
              color="red"
              header="Error"
              content="Name must be unique"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            size="tiny"
            onClick={index === null ? this.handleAdd : this.handleEdit}
            disabled={!alternateName}
            content={action.toUpperCase()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const AlternateNameView = props => {
  const updateAlternateName = (newName = null, index = null) => {
    let names = props.alternateNames;
    if (index == null) {
      names.push({ name: newName });
    } else {
      names[index] = { name: newName };
    }
    props.setAlternateNames(names);
  };

  const deleteAlternateName = index => {
    let names = props.alternateNames;
    names.splice(index, 1);
    props.setAlternateNames(names);
  };

  return (
    <>
      <AlternateNameModal
        updateAlternateName={updateAlternateName}
        addAlternateName={props.addAlternateName}
        type={props.type}
      />
      {props.alternateNames.length > 0 && (
        <Table basic="very" style={{ tableLayout: "fixed" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="sub-header">
                Alternate Names
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.alternateNames.map(({ name }, index) => {
              return (
                <Table.Row key={name}>
                  <Table.Cell style={{ wordWrap: "break-word" }}>
                    {name}
                  </Table.Cell>
                  <Table.Cell textAlign="center" width="2">
                    <AlternateNameModal
                      updateAlternateName={updateAlternateName}
                      alternateName={name}
                      type={props.type}
                      trigger={<TableButton edit />}
                      index={index}
                    />
                    <TableButton
                      delete
                      onClick={() => deleteAlternateName(index)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export default AlternateNameView;
