import React from "react";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import CampaignStatusPresetService from "../../../services/CampaignStatusPreset";

const DeleteCampaignStatusPresetModal = ({ preset, fetchPresets }) => {
  const handleConfirm = async () => {
    await CampaignStatusPresetService.deleteCampaignStatusPreset(preset.id);
    fetchPresets();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete"
      buttonColor="red"
      onConfirm={handleConfirm}
      menuTrigger
      icon="trash"
      warning
    >
      <p>Are you sure you want to delete this Campaign Status Preset?</p>
    </ConfirmationModal>
  );
};

export default DeleteCampaignStatusPresetModal;
